import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Link, withRouter } from "react-router-dom";
import { Navbar, Container, Nav } from 'react-bootstrap';
import useWindowSize from 'react-use/lib/useWindowSize';
import socketIOClient from 'socket.io-client';
import detectEthereumProvider from '@metamask/detect-provider'
import Cookies from "universal-cookie";

import config from './config';

import authService from "./service/AuthService";
import twitterService from "./service/TwitterService";
import refreshService from "./service/RefreshService";
import ApiService from "./service/ApiService";

import './css/App.css';
import './css/animations.css'

import CallbackPage from "./page/CallbackPage";
import ExtensionPage from "./page/ExtensionPage";
import InventoryPage from "./page/InventoryPage";
import CountdownPage from "./page/CountdownPage";
import LandingPage from "./page/LandingPage";
import LeaderboardPage from "./page/LeaderboardPage";
import RewardsPage from "./page/RewardsPage";
import CreateRewardsPage from "./page/CreateRewardsPage";
import AdminPage from "./page/AdminPage";
import ShopPage from "./page/ShopPage";
import AccountPage from "./page/AccountPage";
import ProfilePage from "./page/ProfilePage";
import BuyCreditsPage from "./page/BuyCreditsPage";
import CheckoutPage from "./page/CheckoutPage";
import CheckoutByIdPage from "./page/CheckoutByIdPage";

import { ButtonLarge, Button, Panel } from "./component/sharedComponents/sharedComponents"
import Confetti from './component/Confetti';
import Firework from './component/Fireworks';
import FrameDisplay from './component/FrameDisplay';
import Footer from './component/Footer';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const cookies = new Cookies();

function App() {

    const windowSize = useWindowSize();

    const [isNav, setIsNav] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [isLaunched, setIsLaunched] = useState(false);

    const [isLogged, setIsLogged] = useState(false);
    const [lastUpdate, setLastUpdate] = useState();
    const [userProfile, setUserProfile] = useState({});
    const [rateLimits, setRateLimits] = useState({});
    const [hasPendingCheckout, setHasPendingCheckout] = useState(false);
    const [hasAvailableRewards, setHasAvailableRewards] = useState(false);
    const [pendingCheckoutId, setPendingCheckoutId] = useState('');

    const [websocket, setWebsocket] = useState();

    const [leaderboardData, setLeaderboardData] = useState([]);
    const [shopData, setShopData] = useState([]);
    const [playerCount, setPlayerCount] = useState();

    const [backLink, setBackLink] = useState();

    const [quests, setQuests] = useState([]);

    const [userAddress, setUserAddress] = useState();
    const [hasMetamask, setHasMetamask] = useState(false);

    const [scoreReward, setScoreReward] = useState(100);
    const [creditsReward, setCreditsReward] = useState(10);
    const [isReward, setIsReward] = useState(false);
    const [isWarningAlert, setIsWarningAlert] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [isBought, setIsBought] = useState(false);
    const [boughtDetails, setBoughtDetails] = useState()

    const [cart, setCart] = useState([])

    const location = useLocation();

    useEffect(() => { 
        async function effect() {
            
            // Verify if the url contains a query param `ac=___`
            const currentUrl = new URL(window.location.href);
            const affiliateCode = currentUrl.searchParams.get("ac");

            if(affiliateCode) {
                cookies.set("AFFILIATE_CODE", affiliateCode, { path: "/" });
                window.history.pushState({}, '', window.location.pathname);
                console.log(affiliateCode);
            }

            // dont try anything while user is still on callback page
            if(location.pathname === "/callback" || !isLaunched) { return }
            
            // if not logged attempt continue session
            if(!isLogged && await authService.checkForSession()) {
                setIsLogged(true);
                await refreshProfile();
            }
            
            // if data is more than 30 sec old
            if(isLogged && (typeof lastUpdate === 'undefined' || (Date.now() - lastUpdate) > 30 * 1000)) {
                await refreshProfile();
            }
            
        }; effect(); 
    }, [location, lastUpdate, isLogged, isLaunched])
    
    useEffect(() => {
        async function effect () {
            if(isLaunched) {
                await refreshAppData();
                setIsLoading(false);

                // const provider = await detectEthereumProvider();
                // if (provider) {
                  
                //   // Request the current network ID
                //   const networkId = await provider.request({ method: 'net_version' });
              
                //   // Check if the current network ID is the Ethereum mainnet chain ID (1)
                //   if (networkId === '1') {
                //     setHasMetamask(true);
                //     console.log('User is on the Ethereum mainnet');
                //   } else {
                //     console.log('User is not on the Ethereum mainnet');
                //     // You may want to prompt the user to switch to the Ethereum mainnet here
                //   }
                // } else {
                //   console.log('MetaMask is not installed');
                // }
            }
        }; effect();
    }, [isLaunched]);
    
    useEffect(()=>{
        if(shopData.checkoutsData && shopData.checkoutsData.some(checkout => checkout.status === 'pending')) {
            shopData.checkoutsData.forEach(checkout => {
                if(checkout.status === 'pending') {
                    setPendingCheckoutId(checkout._id)
                }
            })
            setHasPendingCheckout(true);
        } else {
            setPendingCheckoutId();
            setHasPendingCheckout(false);
        }

        setHasAvailableRewards(
            (shopData.checkoutsData && shopData.checkoutsData.some(checkout => checkout.status === 'completed')) ||
            (quests && quests.some(quest => (quest.isCompleted && !quest.isRewardGiven)))
        )
        
    },[shopData, quests])

    useEffect(() => {
        if(isLogged && !websocket) {
            const socket = socketIOClient(config.WEBSOCKET_URL);
            socket.on('connection', () => {
                ApiService.post('/auth/setSocket', {socketId: socket.id})
                setWebsocket(socket);
                
                socket.on('backendEmit', (data) => {
                    switch(data) {
                        case 'checkoutCompleted':
                            console.log('checkoutCompleted');
                            refreshShopData();
                            break;
                        case 'abc': break;
                        default: break;
                    }
                    if(data === 'checkoutCompleted') {
                    }
                })
            })
        }
    }, [websocket, isLogged])

    useEffect(()=>{
        let hasFoundPath = false;

        if(!hasFoundPath && location.pathname.substring(0,8) === '/profile') { setBackLink('/leaderboard'); hasFoundPath = true; }
        if(!hasFoundPath && location.pathname === '/checkout') { setBackLink('/buyCredits'); hasFoundPath = true; }
        if(!hasFoundPath && location.pathname === '/buyCredits') { setBackLink('/shop'); hasFoundPath = true; }
        if(!hasFoundPath) { setBackLink(undefined) };

    }, [location])
    
    async function connectMetamask() {
        const provider = await detectEthereumProvider();

        if (provider) {
            // Request the current network ID
            const networkId = await provider.request({ method: 'net_version' });

            if (networkId === '1') {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setUserAddress(accounts[0]);
            } else {
            console.log('User is not on the Ethereum mainnet');
            // You may want to prompt the user to switch to the Ethereum mainnet here
            }
        } else {
            setHasMetamask(false);
        }
    }

    async function refreshAppData() {
        const response = await refreshService.get();

        if(response.data) {
            setLeaderboardData(response.data.leaderboardData);
            setQuests(response.data.questsData);
            setShopData(response.data.shopData);
            setPlayerCount(response.data.playerCount);
        }

        return response.data;
    } 
    
    async function refresh() {
        await refreshProfile();
        await refreshAppData();
    }

    async function refreshProfile() {
        const profileData = await twitterService.getCurrentUserProfile();
        setUserProfile( profileData.userProfile );
        setRateLimits( profileData.rateLimits );
        setLastUpdate( Date.now() );
    }

    async function refreshShopData() {
        const response = await ApiService.get('/shop')
        if(response.data) {
            setShopData(response.data.shopData)
        }
    }

    async function refreshQuestData() {
        const response = await ApiService.get('/quest');
        if(response.data) {
            setQuests(response.data);
        }
    }

    async function handleNavProfileButton () {
        authService.auth();
    }

    async function doRewardAlert (score, credits) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        setScoreReward(score);
        setCreditsReward(credits);
        setIsReward(true);
    }

    async function doBoughtAlert (details) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        setBoughtDetails(details);
        setIsBought(true);
    }

    function updateScore(scoreDif, creditDif) {
        const newUserProfile = Object.assign({}, userProfile);
        
        if(!isNaN(scoreDif)) {
            newUserProfile.score = newUserProfile.score + scoreDif;
        }
        if(!isNaN(creditDif)) {
            newUserProfile.credits = newUserProfile.credits + creditDif;
        }

        setUserProfile(newUserProfile);
    }

    function doWarnigAlert (message) {
        setWarningMessage(message);
        setIsWarningAlert(true);
        setTimeout(() => {
            setIsWarningAlert(false);
        }, 5000);
    }

    return (
        <>
            {location.pathname !== "/callback" &&
                <Navbar id="nav" style={{background: '#2BABCB'}}>
                    <Container>
                        <Navbar.Brand>
                            <Link to="/" onClick={() => {setIsNav(false)}}>
                                <div id="navBrand" className="heroesLegend" style={{color: 'white'}}>Flock Frenzy</div>
                            </Link>
                        </Navbar.Brand>
                        {isLaunched && 
                            <>
                                {windowSize.width > 990 ? 
                                    <Nav style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center", width: "100%", color: 'white'}}>
                                        <a style={{marginRight: '15px'}} href='https://chrome.google.com/webstore/detail/flock-frenzy/nfadamcgibafofjchgjdbafammlifaie' target='_blank'>Extension</a>
                                        <Link style={{marginRight: '15px'}} to="/leaderboard">Leaderboard</Link>
                                        <Link style={{marginRight: '15px'}} to="/inventory">Inventory</Link>
                                        <Link style={{marginRight: '15px'}} to="/earn">Earn</Link>
                                        <Link style={{marginRight: '15px'}} to="/shop">Shop</Link>

                                        
                                        {isLogged ? 
                                            <Link to={`/account`}>
                                                <ButtonLarge>
                                                    {userProfile.username ? `@${userProfile.username}` : 'Loading...'}
                                                </ButtonLarge>
                                            </Link>
                                        :
                                            <ButtonLarge onClick={handleNavProfileButton}>
                                                Login
                                            </ButtonLarge>
                                        }
                                    </Nav>
                                :
                                    <ButtonLarge 
                                        type={isNav ? 'danger' : 'primary'}
                                        style={{width: '46px', padding: '15px 0px', minWidth: '0px'}}
                                        onClick={()=>{setIsNav(!isNav)}}
                                    >
                                        {isNav ? <AiOutlineClose /> : <AiOutlineMenu />}
                                    </ButtonLarge>    
                                }
                            </>
                        }
                    </Container>
                </Navbar>
            }

            {!isNav &&
                <>
                    <div style={{ position: 'absolute', height: '150px', width: '100%', background: '#2BABCB', zIndex: -1 }} />
                    
                    { location.pathname !== "/callback" && 
                        <Container>
                            <div style={{display: 'grid', gridTemplateColumns: '80px 1fr', justifyContent: 'space-between', paddingTop: '4px'}}>
                                {backLink ? 
                                    <div style={{width: '80px', textAlign: "center"}}>
                                        <Link to={backLink}>
                                            <div style={{background: '#fff', padding: '5px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)', marginBottom: '10px'}}>
                                                &larr; Back
                                            </div>
                                        </Link>
                                    </div>
                                :
                                    <>&nbsp;</>
                                }
                                <div className='flexEnd'>
                                    <div id='navTray'>
                                        <div id='notificationTrayItem' className='flexEnd' style={{ textAlign: "center", marginBottom: '10px'}}>
                                            {isLogged && hasAvailableRewards && 
                                                <Link to='/earn'>
                                                    <div style={{position: 'relative', background: '#fff', padding: '5px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'}}>
                                                        <div>{windowSize.width > 576 ? "Available " : ''}Rewards</div>
                                                        <div className='notification' style={{position: 'absolute', top: '-5px', right: '-5px', background: 'red', height: '20px', width: '20px', borderRadius: '50%'}} />
                                                    </div>
                                                </Link>
                                            }
                                            {isLogged && hasPendingCheckout && 
                                                <div style={{ textAlign: "center"}}>
                                                    <Link to={`/checkout/${pendingCheckoutId}`}>
                                                        <div style={{marginLeft: '10px', position: 'relative', background: '#fff', padding: '5px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'}}>
                                                            <div>{windowSize.width > 576 ? 'Pending ' : ''}Checkout</div>
                                                            <div className='notification' style={{position: 'absolute', top: '-5px', right: '-5px', background: 'red', height: '20px', width: '20px', borderRadius: '50%'}} />
                                                        </div>
                                                    </Link>
                                                </div>
                                            }
                                        </div>

                                        {isLogged && typeof userProfile.score !== 'undefined' && typeof userProfile.credits !== 'undefined' &&
                                            <div id='scoreTrayItem' style={{background: '#fff', padding: '5px 15px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)', justifySelf: 'end', marginBottom: '10px', marginLeft: '10px'}}>
                                                <img src={require('./img/star.png')} alt='points' style={{ height: '24px', width: '24px', transform: 'translateY(-3px)'}} />
                                                &nbsp;
                                                <span style={{whiteSpace: 'nowrap'}}>{userProfile.score.toLocaleString()}</span>

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                <img src={require('./img/coin.png')} alt='points' style={{ height: '24px', width: '24px', transform: 'translateY(-3px)'}} />
                                                &nbsp;
                                                <span style={{whiteSpace: 'nowrap'}}>{userProfile.credits.toLocaleString()}</span>
                                            </div>
                                        }
                                    </div>
                                </div>


                            </div>
                        </Container>
                    }
                    {isLaunched ? 
                        <>
                            {isLoading ? 
                                <Container style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
                                    <Panel style={{padding: '25px', width: '250px'}}>
                                        <img src={require('./img/loading.gif')} />
                                        <div style={{textAlign: 'center'}}>Loading ...</div>
                                    </Panel>
                                </Container>
                            :
                                <Routes>
                                    <Route path="/callback" element={<CallbackPage 
                                        setIsLogged={setIsLogged}
                                        refresh={refresh}
                                    />} />

                                    <Route path="/extension" element={<ExtensionPage 
                                        isLogged={isLogged}
                                        userProfile={userProfile}
                                    />} />

                                    <Route path="/" element={<LandingPage 
                                        isLogged={isLogged}
                                    />} />
                                    
                                    <Route path="/leaderboard" element={<LeaderboardPage 
                                        isLogged={isLogged}
                                        leaderboardData={leaderboardData}
                                        playerCount={playerCount}
                                        shopData={shopData}
                                    />} />

                                    <Route path="/buyCredits" element={<BuyCreditsPage 
                                        isLogged={isLogged}
                                        shopData={shopData}
                                        cart={cart}
                                        setCart={setCart}
                                        hasPendingCheckout={hasPendingCheckout}
                                        pendingCheckoutId={pendingCheckoutId}
                                    />} />

                                    <Route path="/shop" element={<ShopPage 
                                        isLogged={isLogged}
                                        shopData={shopData}
                                        userProfile={userProfile}
                                        doBoughtAlert={doBoughtAlert}
                                        doRewardAlert={doRewardAlert}
                                        refresh={refresh}
                                    />} />

                                    <Route path="/checkout" element={<CheckoutPage 
                                        isLogged={isLogged}
                                        cart={cart}
                                        hasPendingCheckout={hasPendingCheckout}
                                        pendingCheckoutId={pendingCheckoutId}
                                        refreshShopData={refreshShopData}
                                    />} />

                                    <Route path="/checkout/:checkoutId" element={<CheckoutByIdPage 
                                        isLogged={isLogged}
                                        refreshShopData={refresh}
                                        shopData={shopData}
                                        hasMetamask={hasMetamask}
                                        userAddress={userAddress}
                                        connectMetamask={connectMetamask}
                                    />} />
                                    
                                    <Route path="/profile/:username" element={<ProfilePage 
                                        isLogged={isLogged}
                                        userProfile={userProfile}
                                    />} />

                                    <Route path="/earn" element={<RewardsPage 
                                        userProfile={userProfile}
                                        isLogged={isLogged}
                                        quests={quests}
                                        setQuests={setQuests}
                                        shopData={shopData}
                                        setShopData={setShopData}
                                        refresh={refresh}
                                        doRewardAlert={doRewardAlert}
                                        updateScore={updateScore}
                                        doWarnigAlert={doWarnigAlert}
                                    />} />

                                    <Route path="/earn/create" element={<CreateRewardsPage 
                                        userProfile={userProfile}
                                        isLogged={isLogged}
                                        refreshQuestData={refreshQuestData}
                                    />} />

                                    <Route path="inventory" element={<InventoryPage 
                                        userProfile={userProfile}
                                        isLogged={isLogged}

                                    />} />

                                    <Route path="/admin" element={<AdminPage
                                        isLogged={isLogged}
                                        userProfile={userProfile}
                                    />} />

                                    <Route path="/account" element={<AccountPage
                                        isLogged={isLogged}
                                        shopData={shopData}
                                        userProfile={userProfile}
                                        setUserProfile={setUserProfile}
                                        hasMetamask={hasMetamask}
                                        userAddress={userAddress}
                                        connectMetamask={connectMetamask}
                                    />} />
                                </Routes>
                            }
                        </>
                    :
                        <CountdownPage 
                            setIsLaunched={setIsLaunched}
                        />
                    }
                    
                </>
            }

            {isReward && 
                <>
                    <Firework />
                    <Confetti />
                    <div style={{position: "absolute", zIndex: "500", top: '0', right: '0', width: "100%", minHeight: '100vh', paddingTop: "100px", backgroundColor: 'rgba(0.2,0.2,0.2, 1)'}}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{maxWidth: "400px", minHeight: '400px', margin: '10px'}}>
                                <div className="heroesLegend" style={{textAlign: 'center', marginBottom: '5px'}}>
                                    <img src={require('./img/congrats.png')} alt='congrats' style={{width: '250px'}} />
                                </div>

                                <div style={{color: "white", textAlign: 'center', marginBottom: '15px'}}>Collected:</div>

                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    
                                    <div style={{width: '100px', height: '100px', position: 'relative'}}>
                                        <img src={require('./img/star.png')} alt='stars' style={{width: '100px', height: '100px'}} />
                                        <div className='rewardPill'>
                                            {`x${scoreReward}`}
                                        </div>
                                    </div>
                                    
                                    {creditsReward > 0 &&
                                        <div style={{width: '100px', height: '100px', position: 'relative'}}>
                                            <img src={require('./img/coin.png')} alt='coins' style={{width: '100px', height: '100px'}} />
                                            <div className='rewardPill'>
                                                {`x${creditsReward}`}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', paddingTop: '25px'}}>
                                    <Button
                                        style={{backgroundColor: '#92CC41', padding: "5px 10px", width: '150px', marginBottom: '25px'}} 
                                        onClick={()=>{setIsReward(false)}}
                                    >Continue &rarr;</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }  

            {isBought && boughtDetails && 
                <>
                    <Firework />
                    <Confetti />
                    <div style={{position: "absolute", zIndex: "500", top: '0', right: '0', width: "100%", minHeight: '100vh', paddingTop: "100px", backgroundColor: 'rgba(0.2,0.2,0.2, 0.8)'}}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{maxWidth: "400px", minHeight: '400px', margin: '10px'}}>
                                <div className="heroesLegend" style={{textAlign: 'center', marginBottom: '5px'}}>
                                    <img src={require('./img/congrats.png')} alt='congrats' style={{width: '250px'}} />
                                </div>

                                <div style={{color: "white", textAlign: 'center', marginBottom: '15px'}}>Collected:</div>
                                <div style={{color: "white", textAlign: 'center', marginBottom: '15px'}}><b>{boughtDetails.name}</b></div>

                                <FrameDisplay size={200} frame={boughtDetails.imageUrl} profile={userProfile.image_url ? userProfile.image_url.substring(0, userProfile.image_url.lastIndexOf('_'))+'_400x400.jpg' : undefined } />
                                <div style={{display: 'flex', justifyContent: 'center', paddingTop: '25px'}}>
                                    <Button
                                        style={{backgroundColor: '#92CC41', padding: "5px 10px", width: '150px', marginBottom: '25px'}} 
                                        onClick={()=>{setIsBought(false)}}
                                    >Continue &rarr;</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }     

            {isNav && 
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '150px'}}>
                    <a style={{marginBottom: '15px'}} href='https://chrome.google.com/webstore/detail/flock-frenzy/nfadamcgibafofjchgjdbafammlifaie' target='_blank'>Extension</a>
                    <Link style={{marginBottom: '15px'}} onClick={()=>{setIsNav(!isNav)}} to="/leaderboard">Leaderboard</Link>
                    <Link style={{marginBottom: '15px'}} onClick={()=>{setIsNav(!isNav)}} to="/inventory">Inventory</Link>
                    <Link style={{marginBottom: '15px'}} onClick={()=>{setIsNav(!isNav)}} to="/earn">Earn</Link>
                    <Link style={{marginBottom: '15px'}} onClick={()=>{setIsNav(!isNav)}} to="/shop">Shop</Link>
                    {isLogged ? 
                        <Link to={`/account`} onClick={()=>{setIsNav(!isNav)}}>
                            <ButtonLarge>
                                {`@${userProfile.username}`}
                            </ButtonLarge>
                        </Link>
                    :
                        <ButtonLarge onClick={handleNavProfileButton}>
                            Login
                        </ButtonLarge>
                    }
                </div>
            }

            {isWarningAlert && 
                <>
                    <div style={{position: "absolute", zIndex: "500", top: '0', right: '0', width: "100%", minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                        <Panel style={{
                            background: 'linear-gradient(180deg, #ffeb3b 0%, #fdd835 100%)',
                            padding: '12px',
                            marginBottom: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div style={{color: '#333'}}>{warningMessage}</div>
                        </Panel>
                    </div>
                </>
            }
            
            <Footer />
        </>
    );
}

export default App;
