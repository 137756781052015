import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Panel } from '../component/sharedComponents/sharedComponents';
import FrameDisplay from '../component/FrameDisplay';
import { Container, Row, Col } from 'react-bootstrap';
import useWindowSize from 'react-use/lib/useWindowSize';

import authService from '../service/AuthService';
import twitterService from '../service/TwitterService';
import '../css/profilePage.css'

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const metrics = ['score', 'followers_count', 'following_count', 'tweet_count'];
const metricTitles = ['Score', 'Followers', 'Following', 'Tweets'];

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};

export default function ProfilePage ({}) {

    const [chartData, setChartData] = useState();
    
    const [targetProfile, setTargetProfile] = useState({});
    const [targetHistory, setTargetHistory] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const windowSize = useWindowSize();

    const { username } = useParams();
    useEffect(() => {
        async function effect () {
            const profileData = await twitterService.getProfileByUsername(username);
            setTargetProfile(profileData.profile);
            setTargetHistory(profileData.history);
            setIsLoaded(true);
        }; effect();
    }, [username])

    useEffect(()=>{
        if(targetHistory) {
            
            const newChartData = {
                labels: [],
                datasets: [
                    {
                        label: 'Following',
                        data: [],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Followers',
                        data: [],
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            }

            targetHistory.forEach((entry) => {
                newChartData.labels.push(entry.date.substring(0,10));
                newChartData.datasets[0].data.push(entry.following_count)
                newChartData.datasets[1].data.push(entry.followers_count)
            })

            setChartData(newChartData);
        }
    }, [targetHistory])

    function displayProfileInfo() {
        return (
            <div style={{width: '100%'}}>
                <span id='username'>
                    {targetProfile.name}
                </span>
                <br />
                <a 
                    href={`https://twitter.com/${targetProfile.username}`} target="_blank" rel="noreferrer"
                    className='profileLink'
                    id='profileLink'
                >
                    {`@${targetProfile.username}`}
                </a>
                <div>{targetProfile.description}</div>
            </div>
        )
    }
    
    return (
        <Container>
            <Panel>
                {isLoaded && 
                    <Row>
                        <Col xxl={4} xl={12}>
                            <div id='profileTray'>
                                {targetProfile.frameData ? 
                                    <FrameDisplay
                                        size={200} 
                                        frame={targetProfile.frameData.imageUrl} 
                                        profile={
                                            targetProfile.image_url
                                                ? targetProfile.image_url.substring(0, targetProfile.image_url.lastIndexOf('_')) +
                                                '_200x200' +
                                                targetProfile.image_url.substring(targetProfile.image_url.lastIndexOf('.'))
                                                : undefined
                                        } 
                                    />
                                :
                                    <img 
                                        style={{height: '150px', width: '150px', borderRadius: '50%'}} alt="profile"
                                        src={
                                            targetProfile.image_url
                                                ? targetProfile.image_url.substring(0, targetProfile.image_url.lastIndexOf('_')) +
                                                '_200x200' +
                                                targetProfile.image_url.substring(targetProfile.image_url.lastIndexOf('.'))
                                                : undefined
                                        } 
                                    />
                                }
                                {windowSize.width <= 1400 && displayProfileInfo()}
                            </div>
                        </Col>

                        <Col>
                                
                                {windowSize.width > 1400 && displayProfileInfo()}

                                <div id='metricTray'>

                                    <div className='metricBox'>
                                        <div className='metricId'>Rank</div>
                                        <div className='metricValue'>
                                            {!targetProfile.rank ? "---" : targetProfile.rank}
                                        </div>
                                        <div>&nbsp;</div>
                                    </div>

                                    {metrics.map(metric => {
                                        
                                        let change = 0
                                        if(targetHistory.length > 0) {
                                            change = targetProfile[metric] - targetHistory[targetHistory.length - 1][metric]
                                        } 
                                        
                                        return (
                                            <div className='metricBox' key={Math.random()}>
                                                <div className='metricId'>
                                                    {metricTitles[metrics.indexOf(metric)]}&nbsp;
                                                    {metric === 'score' && windowSize.width > 380 && <img src={require('../img/star.png')} alt='points' style={{ height: '25px', width: '25px', transform: 'translateY(-5px)'}} />}
                                                </div>
                                                <div className='metricValue'>
                                                    {!targetProfile[metric] ? '0' : targetProfile[metric]}
                                                </div>
                                                <div className='metricValue'
                                                    style={{fontFamily: 'Roboto', color: change === 0 ? 'black' : change > 0 ? 'green' : 'red' }}
                                                >{change === 0 ? '' : change > 0 ? '+' : ''}{change === 0 ? '--' : change}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                        </Col>
                    </Row>
                }
            </Panel>
            <div style={{height: '25px'}} />
            {chartData && 
                <Panel>
                    Progress chart:
                    <Line options={options} data={chartData}/>
                </Panel>
            }
        </Container>
    )
}
