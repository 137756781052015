import React from 'react'

import authService from "../service/AuthService";

import { Link } from "react-router-dom";

import { Container } from 'react-bootstrap';
import '../css/landing.css';

import { ButtonLarge, Panel } from '../component/sharedComponents/sharedComponents';

export default function LandingPage ({ isLogged }) {
    return (
        <Container  style={{ color: '#303030'}}>

            <Panel style={{padding: '40px'}}>
                <div style={{textAlign: 'center', fontSize: '200%'}}>Engage to Earn</div>
                <div style={{height: "10px"}} />
                <div style={{textAlign: 'center'}}>Join the FlockFrenzy community and start receiving rewards for your interactions!</div>
                <div style={{height: "75px"}} />
                
                <div style={{display: 'flex', justifyContent: 'center'}}>

                    {!isLogged ? 
                        <ButtonLarge id="launchButton" style={{width: 'auto', margin: '5px'}} onClick={()=>{authService.auth()}}>
                            Login
                        </ButtonLarge>
                    :
                        <Link to="/earn" style={{margin: '5px'}}>
                            <ButtonLarge id="launchButton" style={{width: 'auto'}}>
                                Check Rewards
                            </ButtonLarge>
                        </Link>
                    }
                </div>
            </Panel>

            <div style={{height: '25px'}} />

            <Panel style={{padding: '0px'}}>
                <div style={{textAlign: 'center', fontSize: '200%', paddingTop: '40px'}}>Browser Extension</div>
                <div style={{textAlign: 'center', margin: '40px'}}>
                    Get even more with the chrome extension!
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <a href='https://chrome.google.com/webstore/detail/flock-frenzy/nfadamcgibafofjchgjdbafammlifaie' target="_blank">
                        <ButtonLarge id="launchButton" style={{width: 'auto'}}>
                            Chrome store &rarr;
                        </ButtonLarge>
                    </a>
                </div>
                <img src={require('../img/pcdemo.png')} style={{width: '100%'}}/>
            </Panel>

            <div style={{height: '25px'}} />

            <Panel id='gameSplash'>
                <div style={{fontWeight: 'bold', fontSize: '150%'}}>Gamified interactions</div>
                <div>Earn score to climb in ranks</div>
                <Link to="/leaderboard" style={{margin: '5px'}} onClick={() => window.scrollTo(0, 0)}>
                    <ButtonLarge id="launchButton" style={{width: '200px'}}>
                        Leaderboard
                    </ButtonLarge>
                </Link>
            </Panel>

            <div style={{height: '25px'}} />

            <Panel style={{padding: '40px'}}>
                <div style={{textAlign: 'center', fontSize: '200%'}}>Growing community</div>
                <div style={{height: "10px"}} />
                <div style={{textAlign: 'center'}}>Connect with the other members and grow your reach.</div>
                <div style={{height: "75px"}} />

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <a href={`https://twitter.com/flock_frenzy`} target="_blank" rel="noreferrer">
                        <ButtonLarge id="launchButton" style={{width: 'auto', maxWidth: '250px'}}>
                            Official Twitter
                        </ButtonLarge>
                    </a>
                </div>
            </Panel>

        </Container>
    )
}
