import React from 'react';
import styled from 'styled-components';

const Frame = styled.img`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
`;

const Profile = styled.img`
    width: ${props => props.size * 0.66}px;
    height: ${props => props.size * 0.66}px;
    border-radius: 50%;
    position: absolute;
    top: ${props => (props.size - props.size * 0.66) / 2}px;
    left: ${props => (props.size - props.size * 0.66) / 2}px;
`;

export default function FrameDisplay ({ frame, profile, size }) {
       
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ position: 'relative'}}>
                <Frame size={size} src={require(`../img/${frame}`)} alt="Avatar Frame" />
                {profile && <Profile size={size} src={profile} alt="User Profile" />}
            </div>
        </div>
    );
};