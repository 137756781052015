import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const FooterWrapper = styled.div`
  color: white;
  margin-top: 150px;
  padding: 150px 0px;
  background-color: #2BABCB;
`;

const HeroesLegend = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileUsername = styled.a`
`;

const ProfileName = styled.div`
    font-weight: bold;
    font-size: 12px;
`;

function Footer() {
    return (
        <FooterWrapper>
            <Container>
                <HeroesLegend>Flock Frenzy</HeroesLegend>
                <div>
                    <ProfileWrapper>
                        <ProfileImage src="https://pbs.twimg.com/profile_images/1587285381497634822/Lm4RximV_normal.jpg" alt="Twitter profile" />
                        <ProfileInfo>
                            <ProfileName>CARL0S 🚭</ProfileName>
                            <ProfileUsername href='https://twitter.com/CarlosSpacetime'>@CarlosSpacetime</ProfileUsername>
                        </ProfileInfo>
                    </ProfileWrapper>
                    <ProfileWrapper>
                        <ProfileImage src="https://pbs.twimg.com/profile_images/1644705518496120832/Lupt5NQ5_normal.png" alt="Twitter profile" />
                        <ProfileInfo>
                            <ProfileName>Liquid</ProfileName>
                            <ProfileUsername href='https://twitter.com/LiquidBlocks'>@LiquidBlocks</ProfileUsername>
                        </ProfileInfo>
                    </ProfileWrapper>
                </div>
            </Container>
        </FooterWrapper>
  );
}

export default Footer;