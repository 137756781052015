import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import ApiService from '../service/ApiService';
import AuthService from '../service/AuthService';

import { Container } from 'react-bootstrap';
import '../css/landing.css';

import { ButtonLarge, Panel } from '../component/sharedComponents/sharedComponents';

export default function ExtensionPage ({ isLogged, userProfile }) {
    
    const [isValidCode, setIsValidCode] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        async function effect() {
            
            let extensionToken;
            
            const searchParams = new URLSearchParams(window.location.search);
            extensionToken = searchParams.get('extensionToken');

            // verify if a token exists in the cookies
            if(!extensionToken) {
                extensionToken = await AuthService.getExtensionCode();
                AuthService.removeExtensionCode();
            }

            if(extensionToken) {
                let result;
                try {
                    result = await ApiService.post('/extension/auth/login', {extensionToken: extensionToken})
                } catch (error) { console.log(error) }
                    
                    if(result && result.data) {
                        setIsValidCode(true);
                    } else {
                        // save the code in cookies
                        await AuthService.setExtensionCode(extensionToken);
                    }
            }
            setIsCompleted(true);
        }; effect();
    }, [isLogged])
    
    
    return (
        <Container  style={{ color: '#303030'}}>

            <Panel style={{padding: '40px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                {isCompleted ?
                    <>
                        {isValidCode ? 
                            <div style={{color: 'green', margin: '10px'}}>
                                Auth successful.<br />
                                Go back to Twitter and refresh the page.
                            </div>
                        :
                            <div style={{ margin: '10px', textAlign: 'center'}}>
                                <div style={{fontSize: "150%", margin: '24px'}}>One last step:</div>
                                <ButtonLarge onClick={()=>{AuthService.auth()}}>
                                    Login with Twitter
                                </ButtonLarge>
                            </div>
                        }
                        <div style={{height: '100px'}} />
                        <Link to='/'>
                            <ButtonLarge type={isValidCode ? "primary" : "danger"} style={{padding: '0px', fontSize: '70%'}}>
                            {isValidCode ? "Continue to website" : "cancel"}
                            </ButtonLarge>
                        </Link>
                    </>
                :
                    <>
                        <img src={require('../img/loading.gif')} />
                        <div style={{textAlign: 'center'}}>Loading ...</div>
                    </>
                }
            </Panel>

        </Container>
    )
}
