import React, { useState } from 'react'

import ApiService from '../service/ApiService';
import AuthService from '../service/AuthService';

import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents';
import FrameDisplay from '../component/FrameDisplay';
import Countdown from '../component/CountDown';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import background from '../img/pig.png';
import demo from '../img/demo.png'

export default function ShopPage ({ isLogged, shopData, userProfile, doBoughtAlert, doRewardAlert,  refresh }) {

    const [confirmId, setConfirmId] = useState();

    async function handleBuyFrame(frame) {
        if(!confirmId || confirmId !== frame._id) {
            if(frame.creditPrice <= userProfile.credits) {
                setConfirmId(frame._id);
            }
        } else {
            if(isLogged) {
                const response = await ApiService.post('/shop/buy', { itemId: frame._id });
                if(response.data) {
                    doBoughtAlert(frame);
                    refresh(); //allowed since should not happen to often
                };
            } else {
                AuthService.auth();
            }
            setConfirmId();
        }
    }
    
    return (
        <Container>
            <div className='heroesLegend' style={{fontSize:'32px', textAlign: "center", color: "white"}}>Shop</div>
            <div style={{height:"25px"}} />
            <Panel style={{
                background: '#888888',
                marginBottom: '24px',
                background: 'radial-gradient(#e2aa9d, #d89483)',
                color: 'white'
            }}>
                <Row>
                    <Col xs={12} sm={12} md={6} style={{marginBottom: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div>
                            <div className="heroesLegend" style={{marginBottom: '15px', textAlign: 'center'}}>Avatar frames</div>
                            <div style={{textAlign: 'center'}}>Customize your appearance with the very first twitter avatar frames</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} style={{marginBottom: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={demo} style={{borderRadius: '50px', marginTop: '15px'}} />
                    </Col>
                </Row>
                <div style={{textAlign: 'center'}}>Visible to every user of the extension</div>
            </Panel>

            <Panel style={{
                background: 'linear-gradient(180deg, #ffeb3b 0%, #fdd835 100%)',
                padding: '12px',
                marginBottom: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{color: '#333'}}>All frames will be mintable on Polygon soon.</div>
            </Panel>

            <Row>
                
                {shopData && shopData.frames && shopData.frames.map((frame => {
                    if(frame.isAvailable) {
                        return (
                            <Col xs={12} sm={12} md={6} xl={4} xxl={4} key={Math.random()} style={{marginBottom: '24px'}}>
                                <Panel style={{}}>
                                    <div style={{textAlign: 'center', marginBottom: '15px'}}>{`${frame.name} Frame`}</div>
                                    <FrameDisplay size={200} frame={frame.imageUrl} profile={userProfile.image_url ? userProfile.image_url.substring(0, userProfile.image_url.lastIndexOf('_'))+'_400x400'  +
                                                userProfile.image_url.substring(userProfile.image_url.lastIndexOf('.'))
                                                  : undefined } />
                                    <div style={{textAlign: 'center', margin: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55px'}}>
                                        {frame.endDate && <Countdown endTime={Date.parse(frame.endDate)}/>}
                                        {frame.quantity && <>{frame.quantity - frame.distributed}/{frame.quantity}</>}
                                    </div>
                                    
                                    {frame.creditPrice > 0 ?
                                        <ButtonLarge 
                                            type={confirmId === frame._id ? 'success' : 
                                                `${frame.creditPrice <= userProfile.credits ? 'primary' : 'inactive'}`
                                            }
                                            onClick={()=>{handleBuyFrame(frame)}}
                                        >
                                            {confirmId === frame._id ? 
                                                <>Confirm</>
                                            :
                                                <>
                                                    {frame.creditPrice}
                                                    <img src={require('../img/coin.png')} alt='points' style={{ height: '24px', width: '24px', transform: 'translateY(-3px)'}} />
                                                </>
                                            }
                                        </ButtonLarge>
                                    :
                                        <a href='https://chrome.google.com/webstore/detail/flock-frenzy/nfadamcgibafofjchgjdbafammlifaie' target="_blank">
                                            <ButtonLarge 
                                                type='success'
                                                onClick={()=>{}}
                                            >
                                                {`free (Claim in extension)`}
                                            </ButtonLarge>
                                        </a>
                                    }
                                    
                                </Panel>
                            </Col>
                        )
                    }
                }))}
            </Row>



            <Panel style={{
                marginBottom: '24px',
                height: '400px', 
                background: `url(${background})`, 
                backgroundSize: 'cover', 
                backgroundRepeat: 'no-repeat', 
                backgroundPosition: 'bottom left',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '150px'
            }}>
                <Link to='/buyCredits'>
                    <ButtonLarge type='primary' style={{width: '200px'}}>
                        Get Credits &rarr;
                    </ButtonLarge>
                </Link>
            </Panel>
        </Container>
    )
}
