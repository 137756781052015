import React from 'react';

export default function RewardsIcons({ scoreAmount, creditAmount }) {
    return (
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
            {scoreAmount &&
                <div style={{width: '100px', height: '100px', position: 'relative'}}>
                    <img src={require('../img/star.png')} alt='start icon' style={{width: '100px', height: '100px'}} />
                    <div className='rewardPill'>
                        {`x${scoreAmount}`}
                    </div>
                </div>
            }
            
            {(creditAmount !== 0) && creditAmount &&
                <div style={{width: '100px', height: '100px', position: 'relative'}}>
                    <img src={require('../img/coin.png')} alt='start icon' style={{width: '100px', height: '100px'}} />
                    <div className='rewardPill'>
                        {`x${creditAmount}`}
                    </div>
                </div>
            }
        </div>
    );
}

