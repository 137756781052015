import React, { useState, useEffect } from 'react';

export default function Countdown({ endTime, setIsExpired }) {
    const [timeRemaining, setTimeRemaining] = useState(endTime - new Date().getTime());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(endTime - new Date().getTime());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [endTime]);

    useEffect(()=>{
        if(timeRemaining < 0 && typeof setIsExpired === 'function') {
            setIsExpired(true);
        }
    }, [timeRemaining])

    const seconds = Math.floor(timeRemaining / 1000) % 60;
    const minutes = Math.floor(timeRemaining / 1000 / 60) % 60;
    const hours = Math.floor(timeRemaining / 1000 / 60 / 60) % 24;
    const days = Math.floor(timeRemaining / 1000 / 60 / 60 / 24);

    return (
        <div>
            {timeRemaining > 0 ? 
                <div style={{display: 'flex', textAlign: 'center'}}>
                    
                    {days > 0 &&
                        <div style={{margin: '5px'}}>
                            <div>{days}</div>
                            <div style={{fontSize: '70%'}}>days</div>
                        </div>
                    }
                    
                    <div style={{margin: '5px'}}>
                        <div>{hours}</div>
                        <div style={{fontSize: '70%'}}>hours</div>
                    </div>
                    
                    <div style={{margin: '5px'}}>
                        <div>{minutes}</div>
                        <div style={{fontSize: '70%'}}>minutes</div>
                    </div>
                    
                    <div style={{margin: '5px'}}>
                        <div>{seconds}</div>
                        <div style={{fontSize: '70%'}}>seconds</div>
                    </div>
                </div>
            :
                <div style={{color: 'red'}}>
                    Expired
                </div>
            }
        </div>
    );
}