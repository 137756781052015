import React, { useState, useEffect } from "react";
import { Navigate, useLocation, Link } from 'react-router-dom';
import Cookies from "universal-cookie";

import authService from "../service/AuthService";

import { Container } from 'react-bootstrap';

import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents';

const cookies = new Cookies();

export default function CallbackPage({ setIsLogged, refresh }) {

    const location = useLocation();
 
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isPersistent, setIsPersistent] = useState(false);
    const [destination, setDestination] = useState('/leaderboard');

    useEffect(() => {
        async function effect() {
            try {

                const searchParams = new URLSearchParams(location.search);
                
                if(searchParams.get('error')) {
                    cookies.remove("MOBILE_SECRET", { path: "/" });
                    setIsPersistent(true);
                    setHasLoaded(true);
                }

                const mobileSecret = await cookies.get("MOBILE_SECRET");

                if(mobileSecret) {
                    cookies.remove("MOBILE_SECRET", { path: "/" })
                    
                    // verify sets the token on success
                    setIsLogged(await authService.verifyToken(searchParams.get('token')));
                    setIsPersistent(true);
                    refresh();
                } else {
                    setIsLogged(await authService.verifyToken(searchParams.get('token')));
                }

                const extensionCode = await authService.getExtensionCode();
                if(extensionCode) {
                    setDestination('/extension')
                }
                // on callback, get the extensionCode
                // if the extension code exists
                // send user to extension page

            } catch (error) {
                console.log(error);
                setIsLogged(false);
            }
            setHasLoaded(true);
        }
        effect();
    }, [setIsLogged, location]);

    return (
        <>
            {hasLoaded ?
                <>
                    {isPersistent ? 
                        <Navigate to={destination} />
                    : // not persistent
                        <>

                            <Container style={{textAlign: "center"}}>
                                
                                <div style={{height: "50px"}} />
                                <Panel>
                                    <div id='mainTitle' className='heroesLegend' style={{margin: '50px'}}>Flock Frenzy</div>
                                    <div style={{textAlign: 'center', fontSize: '70%'}}>Successful Twitter authentication!</div>
                                    <br />
                                    <div style={{textAlign: 'center'}}>Go back to the browser app and refresh the page.</div>
                                    <div style={{margin: '25px'}}>or</div>
                                    <div style={{display: 'flex', justifyContent: 'center', margin: '25px'}}>
                                        
                                        <Link to='/leaderboard' onClick={()=>{refresh()}}>
                                            <span style={{textDecoration: 'underline'}}>Continue in twitter app</span> &rarr;
                                        </Link>
                                        
                                    </div>

                                </Panel>
                            </Container>                           
                        </>
                    }
                </>
            : // is not loaded
                <div style={{textAlign: "center", paddingTop: "50px"}}>Redirect in progress...</div>
            }
        </>
    );
}