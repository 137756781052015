import React, { useState } from 'react';
import styled from 'styled-components';

import { Container } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';

import { SiEthereum } from 'react-icons/si';
import { BiCopy, BiCheck } from 'react-icons/bi';
import metamaskIcon from '../img/metamask-icon.webp';

import { ethers } from 'ethers';
import { SiweMessage } from 'siwe';

import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents'
import ProfileBox from '../component/ProfileBox';
import FrameDisplay from '../component/FrameDisplay';

import AuthService from '../service/AuthService';
import ApiService from '../service/ApiService';

const FindTweetButton = styled.div`
  height: 50px;
  background: #5CD1F0;
  border-radius: 50px 0px 0px 50px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 125px;
  font-size: 70%;
`;

export default function AccountPage({ isLogged, userProfile, setUserProfile, shopData, hasMetamask, userAddress, connectMetamask }) {

    const windowSize = useWindowSize();
    const navigate = useNavigate();

    const [isCopied, setIsCopied] = useState(false);

    async function handleMetamaskButton() {
        if(!userAddress) {
            connectMetamask();
        } else {
            const result = await signInWithEthereum();
            if(result) {
                setUserProfile({ ...userProfile, eth_address: result});
            }
        }
    }

    async function signInWithEthereum () {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const message = await createSiweMessage(
            await signer.getAddress(), 
            'Sign in with Ethereum to the app.'
        );

        const signature = await signer.signMessage(message);

        const response = await ApiService.post('/auth/siwe/verify', { message: message, signature: signature });
        
        return response.data
    }

    async function createSiweMessage (address, statement) {
        const res = await ApiService.get('/auth/siwe/nonce', {address: address});
        const message = new SiweMessage({
            domain: window.location.host,
            address,
            statement,
            uri: window.location.origin,
            version: '1',
            chainId: '1',
            nonce: res.data
        });
        return message.prepareMessage();
    }

    async function handleRemoveAddress() {
        const response = await ApiService.post('/twitter/removeAddress');
        if(response.data) {
            const { eth_address, ...newUserProfile} = userProfile;
            setUserProfile(newUserProfile);
        }
    }

    async function handleCopyAddr() {
        window.navigator.clipboard.writeText(userProfile.eth_address);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 7000);
    }

    return (
        <>
            {isLogged ? 
                <Container>
                    <div className='heroesLegend pageTitle' style={{ fontSize: '32px',textAlign: "center", color: 'white'}}>Account</div>
                    <div style={{height: "25px"}}/>
                    <Panel>
                        <div style={{marginBottom: '15px'}}>Logged in as:</div>
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '15px'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex'}}>
                                    {userProfile.frameData ? 
                                        <FrameDisplay
                                            size={200} 
                                            frame={userProfile.frameData.imageUrl} 
                                            profile={
                                                userProfile.image_url
                                                    ? userProfile.image_url.substring(0, userProfile.image_url.lastIndexOf('_')) +
                                                    '_200x200' +
                                                    userProfile.image_url.substring(userProfile.image_url.lastIndexOf('.'))
                                                    : undefined
                                            } 
                                        />
                                    :
                                        <img 
                                            style={{height: '150px', width: '150px', borderRadius: '50%'}} alt="profile"
                                            src={
                                                userProfile.image_url
                                                    ? userProfile.image_url.substring(0, userProfile.image_url.lastIndexOf('_')) +
                                                    '_200x200' +
                                                    userProfile.image_url.substring(userProfile.image_url.lastIndexOf('.'))
                                                    : undefined
                                            } 
                                        />
                                    }
                                    <div style={{width: '15px'}} />
                                    <ProfileBox profile={userProfile} />
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
                                    <ButtonLarge 
                                        type='primary'
                                        // style={{padding: '5px', width: '150px'}}
                                        onClick={()=>{navigate(`/profile/${userProfile.username}`)}}
                                    >Go to profile &rarr;</ButtonLarge>
                                </div>
                            </div>
                        </div>
                        <ButtonLarge 
                            type='danger' style={{padding: '5px', width: '150px', fontSize: '70%'}}
                            onClick={()=>{AuthService.logout();}}
                        >Logout</ButtonLarge>
                    </Panel>
                    <div style={{height: "25px"}}/>
                    
                    {/* <Panel>
                        <div style={{marginBottom: '15px'}}>Ethereum Wallet:</div>
                        

                        {(userProfile && userProfile.eth_address) ? 
                            <>
                                <div style={{fontSize: '70%'}}>Current ethereum address:</div>
                                <div 
                                    style={{
                                        borderRadius: '50px', 
                                        display: 'grid',  
                                        height: '50px', 
                                        gridTemplateColumns: '50px 1fr',
                                        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
                                        cursor: 'pointer',
                                        margin: '15px 0px'
                                    }}
                                    onClick={handleCopyAddr}
                                >
                                    <div style={{height: '50px', width: '50px', background: isCopied ? '#92CC41' : '#5CD1F0', borderRadius: '50px 0px 0px 50px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '150%'}}>
                                        {isCopied ? <BiCheck /> : <BiCopy />}
                                    </div>
                                    <div className="longHex" style={{fontFamily: 'monospace'}}>
                                        {windowSize.width > 768 ? userProfile.eth_address : `${userProfile.eth_address.substring(0,10)}...${userProfile.eth_address.substring(32,42)}`}
                                    </div>
                                </div>
                                <ButtonLarge 
                                    type='danger' style={{padding: '5px', width: '150px', fontSize: '70%'}}
                                    onClick={handleRemoveAddress}
                                >Remove</ButtonLarge>
                            </>
                        :
                            <>
                                {hasMetamask ? 
                                    <>
                                        {userAddress && 
                                            <div 
                                                style={{
                                                    borderRadius: '50px', 
                                                    display: 'flex',  
                                                    height: '50px', 
                                                    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
                                                    marginBottom: '15px'
                                                }}
                                            >
                                                <FindTweetButton>
                                                    Detected&nbsp;Address:
                                                </FindTweetButton>
                                                <div className="longHex" style={{fontFamily: 'monospace', textAlign: 'center', width: '100%'}}>
                                                    {windowSize.width > 768 ? userAddress : `${userAddress.substring(0,10)}...${userAddress.substring(32,42)}`}
                                                </div>
                                            </div>
                                        }
                                        <ButtonLarge type={userAddress ? 'success' : 'primary'} onClick={handleMetamaskButton}>
                                            {userAddress ? 'Sign to link accounts' : 'Connect Metamask'}
                                            &nbsp;<img style={{width: '28px', height: '28px'}} src={metamaskIcon}/>
                                        </ButtonLarge>
                                    </>
                                :
                                    <div style={{textAlign: 'center', color: 'red', marginBottom: '15px'}}>Must install metamask to use this feature</div>
                                }
                            </>
                        }
                    </Panel> */}
                    
                    <div style={{height: "25px"}}/>
                    <Panel>
                        <div>Checkouts:</div>
                        <hr />
                        <table style={{width: '100%', tableLayout: 'fixed'}}>
                            <tbody>
                                <tr>
                                    <th style={{fontSize: '70%'}}>Checkout Id</th>
                                    <th style={{fontSize: '70%'}}>Total <SiEthereum /></th>
                                    <th style={{fontSize: '70%', width: '110px'}}>Status</th>
                                </tr>
                                {shopData.checkoutsData && shopData.checkoutsData.map(checkout => {
                                    return (
                                        <tr style={{cursor: 'pointer'}} key={Math.random()} onClick={()=>{navigate(`/checkout/${checkout._id}`)}}>
                                            <td>
                                                {checkout._id ? checkout._id.substring(20,24) : ''}
                                            </td>
                                            <td>{checkout.total}</td>
                                            <td style={{color: checkout.status === 'completed' ? 'lime' : ['canceled', 'expired'].includes(checkout.status) ? 'red' : 'black'}}>{checkout.status}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        
                    </Panel>
                </Container>
                
            : // else
                <Navigate to="/" />
            }
        </>
    )
}