import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents';
import LikeTypePanel from '../component/rewardPanels/LikeTypePanel';
import RewardBox from '../component/RewardBox';
import ApiService from '../service/ApiService';

import '../css/createRewards.css'
import { BiSearch } from 'react-icons/bi';

const FindTweetButton = styled.div`
  height: 50px;
  background: ${(props) => (props.isValidUrl ? '#5CD1F0' : '#ff6b6b')};
  border-radius: 50px 0px 0px 50px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    font-size: 100%;
  }

  @media (max-width: 480px) {
    font-size: 70%;
  }
`;

export default function CreateRewardsPage ({ isLogged, userProfile, refreshQuestData }) {

    const [tweetUrl, setTweetUrl] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [selectedReward, setSelectedReward] = useState();
    const [selectedTweet, setSelectedTweet] = useState();
    const [step, setStep] = useState(0);
    const [templates, setTemplates] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        async function effect () {
            const response = await ApiService.get('/quest/templates');
            setTemplates(response.data);
        } effect();
    }, [])
    

    const handleTweetUrlChange = (event) => {
        const tweetUrl = event.target.value;
        const tweetUrlRegex = /https:\/\/twitter.com\/\w+\/status\/\d+/;
        if(tweetUrl.toLowerCase().includes(userProfile.username.toLowerCase())) {
            setIsValidUrl(tweetUrlRegex.test(tweetUrl));
        } else {
            setIsValidUrl(false);
        }
        setTweetUrl(tweetUrl);
    }

    const handleNextStepButton = async () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        switch (step) {
            case 0:
                setStep(1);
                break;
            case 1:
                setStep(2);
                break;
            case 2:
                const response = await ApiService.put('/quest/create', {templateId: selectedReward._id, tweetId: selectedTweet.tweetId});
                if(response.data) {
                    refreshQuestData();
                    navigate('/earn');
                }
                break;

            default: break;
        }
    }

    async function handleFetchTweetData() {
        if(isValidUrl) {
            const tweetId = tweetUrl.substring(tweetUrl.lastIndexOf('/')+1, tweetUrl.lastIndexOf('?') === -1 ? tweetUrl.length : tweetUrl.lastIndexOf('?'));
            const response = await ApiService.get(`/twitter/tweet/${tweetId}`);
            if(response.data){
                setSelectedTweet(response.data);
            }
        }
    }

    const handleRewardBoxSelected  = (newSelection) => {
        setSelectedReward(newSelection);
    }

    function parseDate(IsoDate) {
        const createdAt = new Date(IsoDate);
        return createdAt.toDateString();
    }

    return (
        <>
            {isLogged ?
                <Container>
                    <div className='heroesLegend pageTitle' style={{ fontSize: '32px',textAlign: "center", color: 'white'}}>Engagement Rewards</div>
                    <div style={{height: "25px"}}/>
                    <Panel id='likeRewardsPanel'>
                        <div className='panelTitle'>For each like</div>
                        <div className='panelDescription'>For every like that your chosen post receives, you and the other user who liked it will receive a reward.</div>
                        <hr />
                        
                        {step === 0 && 
                            <>
                                <div id='rewardsTray'>
                                    {templates && templates.map(template => {
                                        return (
                                            <RewardBox key={Math.random()} handleRewardBoxSelected={handleRewardBoxSelected} handleNextStepButton={handleNextStepButton} selectedReward={selectedReward} template={template}/>
                                        )
                                    })}
                                </div>
                                {/* <hr />
                                <ButtonLarge type={selectedReward ? 'primary' : 'inactive' } onClick={selectedReward ? handleNextStepButton : ()=>{}}>Next &rarr;</ButtonLarge> */}
                            </>
                        }

                        {step === 1 &&
                            <>
                                <div style={{margin: '15px', textAlign: 'center'}}>
                                    <label htmlFor='tweetUrl' style={{marginRight: '5px', color: 'white'}}>Enter Tweet URL:</label><br />
                                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                        <div 
                                            style={{
                                                borderRadius: '50px', 
                                                display: 'flex', 
                                                width: '100%', 
                                                height: '50px', 
                                                background: 'white',
                                                justifyContent: 'space-between',
                                                alignItems: 'center', 
                                                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <FindTweetButton isValidUrl={isValidUrl} onClick={handleFetchTweetData}>
                                                Find&nbsp;Tweet&nbsp;
                                                <BiSearch />
                                            </FindTweetButton>
                                            <input type='text' id='tweetUrl' value={tweetUrl} style={{fontFamily: 'monospace', backgroundColor: 'rgba(0,0,0,0)', padding: '5px', border: `none`, width: '100%', margin: '0 15px'}} onChange={handleTweetUrlChange} />
                                        </div>
                                        </div> 
                                </div>
                                {selectedTweet ? 
                                    <Panel>
                                        <div style={{display: 'block'}}>
                                            <div style={{fontFamily: 'Roboto'}}>
                                                {userProfile.name}
                                            </div>
                                            <div style={{fontSize: '70%', color: 'lightblue', marginBottom: '5px'}}>{`@${userProfile.username}`}</div>
                                            <div style={{textAlign: 'justify', marginBottom: '10px'}}>
                                                {selectedTweet.text}
                                            </div> 
                                            <div style={{fontSize: '80%'}}>{parseDate(selectedTweet.created_at)}</div>
                                        </div>
                                    </Panel>
                                :
                                    <div style={{fontSize: '70%', textAlign: 'center', color: 'white'}}>Will only work with your own tweet.</div>
                                }
                                <hr />
                                <ButtonLarge type={selectedTweet ? 'primary' : 'inactive' } onClick={selectedTweet ? handleNextStepButton : ()=>{}}>Next &rarr;</ButtonLarge>
                            </>
                        }

                        {step === 2 && 
                            <>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <div style={{maxWidth: '420px'}}>
                                        <LikeTypePanel isLogged={isLogged} disableButtons={true} quest={{
                                            score_reward: selectedReward.score_reward,
                                            credits_reward: selectedReward.credit_reward,
                                            targetTweet: selectedTweet,
                                            targetProfile: userProfile
                                        }} />
                                    </div>
                                </div>
                                <div style={{marginTop: '15px', textAlign: 'center', color: 'white'}}>Will last 2h.</div>
                                <div style={{textAlign: 'center', color:'white'}}>
                                    Cost: <b>{selectedReward.cost}</b>
                                    <img src={require('../img/coin.png')} alt='points' style={{ height: '24px', width: '24px', transform: 'translateY(-3px)'}} />
                                </div>
                                <hr />
                                <ButtonLarge type={userProfile.credits >= selectedReward.cost ? 'success' : 'inactive'} onClick={handleNextStepButton}>Confirm</ButtonLarge>
                            </>
                        }
                        
                        {step !== 0 &&
                            <div style={{textAlign: 'center', marginTop: '15px', color: 'white', cursor: 'pointer'}} onClick={()=>{setStep(step - 1)}}>
                                &larr; <span style={{textDecoration: 'underline'}}>back</span>
                            </div>
                        }
                    </Panel>
                </Container>
            :
                <Navigate to={'/earn'} />
            }
        </>
    )
}
