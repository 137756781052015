import apiService from "./ApiService";
import Cookies from "universal-cookie";

class AuthService {

    constructor() {
        this.cookies = new Cookies();
        this.basePath = "auth";
    }

    // Call this to start Twitter auth process
    async auth() {
        const affiliateCode = await this.getAffiliateCode();
        const response = await apiService.get(`/${this.basePath}${affiliateCode ? `?ac=${affiliateCode}` : ""}`);
        await this.cookies.set("MOBILE_SECRET", response.data.mobileSecret, { path: "/" })
        window.location = response.data.url;
    }

    async checkForSession() {
        const token = await this.getToken();
        const hasSession = await this.verifyToken(token).catch(() => { 
            this.removeToken();
            return false; 
        });
        if(hasSession) {
            this.removeMobileToken();
            return true;
        } else {
            const mobileSecret = await this.getMobileSecret();
            if(mobileSecret) {
                const newToken = await this.validateMobileTokens(mobileSecret);
                if( newToken ){
                    this.removeMobileToken();
                    return await this.verifyToken(newToken).catch(() => { 
                        this.removeToken();
                        return false; 
                    });
                }
            }
        }
        return false;
    }

    async verifyToken(token) {
        if(typeof token === 'undefined' || token === '') { throw new Error(`Could not validate undefined or empty token: ${token}`) };
        apiService.tempSetBearerToken(token);
        await apiService.get(`${this.basePath}/verify`).catch(error => { 
            throw new Error(`Could not validate token because of error: ${error}`) 
        });
        await this.setToken(token);
        return true;
    }

    async validateMobileTokens(mobileSecret) {
        const response = await apiService.get(`/${this.basePath}/verify/mobile`, {mobileSecret: mobileSecret});
        if(response.data) { 
            return response.data;
        } else {
            return false;
        }
    }

    async getAffiliateCode() {
        return this.cookies.get("AFFILIATE_CODE");
    }

    async getToken() {
        return this.cookies.get("TOKEN"); 
    }

    async getMobileSecret() {
        return this.cookies.get("MOBILE_SECRET");
    }

    async setToken(token) {
        await this.cookies.set("TOKEN", token, { path: "/" });
    }

    async setExtensionCode(extensionCode) {
        await this.cookies.set("EX_CODE", extensionCode, { path: "/" });
    }

    async getExtensionCode() {
        return this.cookies.get("EX_CODE"); 
    }

    removeExtensionCode() {
        this.cookies.remove("EX_CODE", { path: "/" }); 
    }

    removeToken() {
        this.cookies.remove("TOKEN", { path: "/" })
    }

    removeMobileToken() {
        this.cookies.remove("MOBILE_SECRET", { path: "/"})
    }

    logout() {
        this.removeToken();
        window.location = window.location.origin;
    }
}

export default new AuthService();