import React, { useState, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import '../css/leaderboard.css';
import '../css/checkoutById.css';
import useWindowSize from 'react-use/lib/useWindowSize';
import { BiCopy, BiCheck } from 'react-icons/bi';
import { SiEthereum } from 'react-icons/si'
import metamaskIcon from '../img/metamask-icon.webp';
import RewardsIcons from '../component/RewardsIcons';

import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents';
import ApiService from '../service/ApiService';

export default function CheckoutByIdPage ({ isLogged, refreshShopData, shopData, hasMetamask, userAddress, connectMetamask }) {

    const [checkoutData, setCheckoutData] = useState({});
    const [sentTx, setSentTx] = useState();

    const [isCopied, setIsCopied] = useState(false);
    const [isTxCopied, setIsTxCopied] = useState(false);
    
    const { checkoutId } = useParams();
    const windowSize = useWindowSize();

    async function handleCopyAddr() {
        window.navigator.clipboard.writeText(checkoutData.address);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 7000);
    }

    async function handleCopyTx() {
        window.navigator.clipboard.writeText(sentTx);
        setIsTxCopied(true);
        setTimeout(() => {
            setIsTxCopied(false);
        }, 7000);
    }

    async function refreshCheckoutData() {
        const response = await ApiService.get(`/shop/checkout/${checkoutId}`);
        setCheckoutData(response.data);
        if(response.data.txHash) {
            setSentTx(response.data.txHash)
        }
    }

    useEffect(() => {
        async function effect () {
            await refreshCheckoutData();
        }; effect();
    }, [checkoutId, shopData]);

    async function handleCancelCheckout() {
        const response = await ApiService.post('/shop/checkout/cancel', {checkoutId: checkoutId});
        if(response.data !== 'pending') {
            await refreshShopData();
        }
    }

    async function handleMetamaskButton() {
        if(userAddress) {

            const tx = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: userAddress,
                        to: checkoutData.address,
                        value: (checkoutData.total * 10 ** 18).toString(16),
                    },
                ],
            })

            if(tx) {
                setSentTx(tx);
                await ApiService.post('/shop/checkout/sentTx', {checkoutId: checkoutId, sentTx: tx});
            }

        } else {
            connectMetamask();
        }
    }
    

    return (
        <>
            {isCopied && 
                <div    
                    style={{position: 'absolute', top: '0', width: '100%', textAlign: 'center', backgroundColor: "lightblue", boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}
                >Copied address!</div>
            }
            
            { isLogged ?
                <Container>
                    <Panel>
                        <div className='heroesLegend pageTitle' style={{textAlign: "center", color: '#303030'}}>Checkout</div>
                        <hr style={{marginBottom: '5px'}}/>
                        <RewardsIcons scoreAmount={checkoutData.packs ? 100 * checkoutData.packs.length : 100} creditAmount={checkoutData.totalCredit} />
                        <hr />
                    

                        <div style={{fontSize: '250%', fontFamily: 'Roboto', textAlign: 'center', margin: '20px'}}>&nbsp;&nbsp;{checkoutData.total}<SiEthereum /></div>

                        {checkoutData.status === 'pending' && <div>
                            {hasMetamask && 
                                <>
                                    {sentTx ? 
                                        <div 
                                            style={{
                                                borderRadius: '50px', 
                                                display: 'grid',  
                                                height: '50px', 
                                                gridTemplateColumns: '50px 1fr',
                                                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
                                                cursor: 'pointer'
                                            }}
                                            onClick={handleCopyTx}
                                        >
                                            <div style={{height: '50px', width: '50px', background: isTxCopied ? '#92CC41' : '#5CD1F0', borderRadius: '50px 0px 0px 50px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '150%'}}>
                                                {isTxCopied ? <BiCheck /> : <BiCopy />}
                                            </div>
                                            <div className="longHex" style={{fontFamily: 'monospace'}}>
                                                {windowSize.width > 768 ? sentTx : `${sentTx.substring(0,16)}...${sentTx.substring(50,66)}`}
                                            </div>
                                        </div>
                                    :
                                        <ButtonLarge type={userAddress ? 'success' : 'primary'} onClick={handleMetamaskButton}>
                                            {userAddress ? 'Pay with metamask' : 'Connect Metamask' }
                                            &nbsp;<img style={{width: '28px', height: '28px'}} src={metamaskIcon}/>
                                        </ButtonLarge>
                                    }

                                    <div style={{textAlign: 'center', margin: '20px'}}>or</div>
                                </>
                            }

                            <div className='smallText' style={{marginBottom: '5px'}}>Send exactly {checkoutData.total} <SiEthereum /> to the following address:</div>
                            <div 
                                style={{
                                    borderRadius: '50px', 
                                    display: 'grid',  
                                    height: '50px', 
                                    gridTemplateColumns: '50px 1fr',
                                    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
                                    cursor: 'pointer'
                                }}
                                onClick={handleCopyAddr}
                            >
                                <div style={{height: '50px', width: '50px', background: isCopied ? '#92CC41' : '#5CD1F0', borderRadius: '50px 0px 0px 50px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '150%'}}>
                                    {isCopied ? <BiCheck /> : <BiCopy />}
                                </div>
                                <div className='longHex' style={{fontFamily: 'monospace'}}>
                                    {checkoutData.address}
                                </div>
                            </div>
                        </div>}

                        {checkoutData.status === 'canceled' && 
                            <div style={{textAlign: 'center'}}>
                                This Checkout was <span style={{color: 'red'}}>canceled</span>
                            </div>
                        }

                        {checkoutData.status === 'expired' && 
                            <div style={{textAlign: 'center'}}>
                                This Checkout <span style={{color: 'red'}}>expired</span>
                                <div>Open a new one in the shop</div>
                            </div>
                        }

                        {checkoutData.status === 'completed' && 
                            <div style={{textAlign: 'center'}}>
                                This Checkout was <span style={{color: 'lime'}}>completed</span>
                            </div>
                        }

                        <div style={{height: '25px'}} />
                        
                    </Panel>


                    <div style={{height: '25px'}} />
                    
                    
                    <Panel>
                        <div style={{marginBottom: '15px', fontSize: '120%'}}>Details</div>
                        <div className='smallText'>checkout id: {checkoutData._id}</div>
                        <div className='smallText' style={{marginBottom: '15px'}}>status: {checkoutData.status}</div>
                        <table style={{width: '100%', tableLayout: 'fixed'}}>
                            <tbody>
                                <tr style={{fontSize: '70%'}}>
                                    <th>Pack Id</th>
                                    <th>Credit Amount</th>
                                    <th>Price <SiEthereum /></th>
                                </tr>
                                {checkoutData.packsById && checkoutData.packs.map(pack => {
                                    return (
                                        <tr key={Math.random()}>
                                            <td>
                                                {pack.substring(20,24)}
                                            </td>
                                            <td>
                                                {checkoutData.packsById[pack].creditAmount}
                                            </td>
                                            <td>
                                                {checkoutData.packsById[pack].price}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr style={{borderTop: '1px solid rgba(0,0,0,0.25)'}}>
                                    <td><b>Total:</b></td>
                                    <td>{checkoutData.totalCredit}</td>
                                    <td>{checkoutData.total} <SiEthereum /></td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{height: '25px'}} />
                        {checkoutData.status === 'pending' && 
                            <ButtonLarge 
                                type='danger' style={{padding: '5px', width: '150px', fontSize: '70%'}}
                                onClick={handleCancelCheckout}
                            >Cancel Checkout</ButtonLarge>
                        }
                    </Panel>
                    <div style={{height: "250px"}}/>
                </Container>
            :
                <Navigate to='/leaderboard' />
            }
        </>
    )
}
