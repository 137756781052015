import React from 'react'
import styled from 'styled-components';

import authService from "../service/AuthService";

import { Link } from "react-router-dom";

import { Container } from 'react-bootstrap';
import '../css/landing.css';

import { ButtonLarge, Panel } from '../component/sharedComponents/sharedComponents';
import Countdown from '../component/CountDown';

const CountdownWrapper = styled.div`
    box-shadow: 0 0 10px #ccc;
    border-radius: 15px;
    padding: 25px;
    font-size: 200%;

    @media (max-width: 768px) {
        font-size: 150%;
    }
`;

// const LAUNCH_DATE = Date.parse('2023-04-13T19:00:00.000Z');
const LAUNCH_DATE = Date.parse('2023-03-13T19:00:00.000Z');

export default function CountdownPage ({ setIsLaunched }) {
    return (
        <Container  style={{ color: '#303030'}}>

            <Panel style={{padding: '0px'}}>
                <div style={{textAlign: 'center', fontSize: '200%', paddingTop: '40px'}}>Engage to Earn</div>
                <div style={{textAlign: 'center', margin: '25px'}}>Install the Chrome extension and start receiving rewards for your interactions!</div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CountdownWrapper>
                        <Countdown endTime={LAUNCH_DATE} setIsExpired={setIsLaunched} />
                    </CountdownWrapper>
                </div>
                <img src={require('../img/pcdemo.png')} style={{width: '100%'}}/>
            </Panel>

            <div style={{height: '25px'}} />

            <Panel id='gameSplash'>
                <div style={{fontWeight: 'bold', fontSize: '150%'}}>Gamified interactions</div>
                <div>Earn score to climb in ranks</div>
                {/* <Link to="/leaderboard" style={{margin: '5px'}} onClick={() => window.scrollTo(0, 0)}>
                    <ButtonLarge id="launchButton" style={{width: '200px'}}>
                        Leaderboard
                    </ButtonLarge>
                </Link> */}
            </Panel>

            <div style={{height: '25px'}} />

            <Panel style={{padding: '40px'}}>
                <div style={{textAlign: 'center', fontSize: '200%'}}>Growing community</div>
                <div style={{textAlign: 'center', margin: '25px'}}>Connect with the project and team members.</div>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <a href={`https://twitter.com/flock_frenzy`} target="_blank" rel="noreferrer">
                        <ButtonLarge id="launchButton" style={{width: 'auto', maxWidth: '250px'}}>
                            Official Twitter
                        </ButtonLarge>
                    </a>
                </div>
            </Panel>

        </Container>
    )
}
