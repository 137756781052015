import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import '../css/leaderboard.css';

import { SiEthereum } from 'react-icons/si'

import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents';
import CartSummaryTable from '../component/CartSummaryTable';
import authService from '../service/AuthService';
import ApiService from '../service/ApiService';

export default function CheckoutPage ({ isLogged, cart, setCart, hasPendingCheckout, pendingCheckoutId, refreshShopData }) {

    const navigate = useNavigate();

    async function handleCheckout() {
        const response = await ApiService.post('/shop/checkout', {packs: cart.reduce((acc, pack) => [...acc, pack.id],[])});
        if(response.data.checkoutId) {
            refreshShopData();
            navigate(`/checkout/${response.data.checkoutId}`);
        }
    }

    return (
        <>
            {cart.length > 0 && !hasPendingCheckout ?
                <Container>
                    <Panel>
                        <div className='heroesLegend pageTitle' style={{textAlign: "center", color: '#303030'}}>Checkout</div>
                        <hr style={{marginBottom: '5px'}}/>
                        <CartSummaryTable cart={cart} />
                        <div style={{height: "25px"}}/>
                        {isLogged ? 
                            <ButtonLarge type={'success'} onClick={handleCheckout}>
                                Confirm &rarr;
                            </ButtonLarge>
                        :
                            <ButtonLarge type={'primary'} onClick={()=>{authService.auth()}}>
                                Login
                            </ButtonLarge>
                        }
                    </Panel>
                    <div style={{height: "250px"}}/>
                </Container>
            :
                <Navigate to={hasPendingCheckout ? `/checkout/${pendingCheckoutId}` : '/shop'} />
            }
        </>
    )
}
