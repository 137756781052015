import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom';

import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents';
import FrameDisplay from '../component/FrameDisplay';
import background from '../img/itemBottle.png';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import ApiService from '../service/ApiService';

export default function ShopPage ({ isLogged, userProfile }) {
    
    const [inventory, setInventory] = useState([]);
    const [selectedFrameId, setSelectedFrameId] = useState();

    useEffect(() => {
        async function effect() {
            const response = await ApiService.get('/account/inventory');
            if(response.data) {
                console.log(response.data);
                setInventory(response.data);
            }
        } effect();
    }, [])
    
    async function handleEquipFrame(frameId) {
        if(selectedFrameId === frameId) {
            // do the funny thing
            const response = await ApiService.post('/account/setFrame', {templateId: frameId})
            if(response.data) {
                console.log('updated frame');
            }
        } else {
            setSelectedFrameId(frameId);
        }
    }

    return (
        <>
            {isLogged ? 
                <Container>
                    <div className='heroesLegend' style={{fontSize:'32px', textAlign: "center", color: "white"}}>Inventory</div>
                    <div style={{height:"25px"}} />

                    <Row>
                        {(inventory && inventory.length > 0) ?
                            inventory.map(item => {
                                return(
                                    <Col xs={12} sm={12} md={6} xl={4} xxl={4} key={Math.random()} style={{marginBottom: '24px'}}>
                                        <Panel style={{textAlign: 'center'}}>
                                            <div style={{fontSize: '150%', fontWeight: 'bold'}}>{item.name}</div>
                                            <FrameDisplay 
                                                size={200} 
                                                frame={item.imageUrl} 
                                                profile={userProfile.image_url ? userProfile.image_url.substring(0, userProfile.image_url.lastIndexOf('_'))+'_400x400' +
                                                userProfile.image_url.substring(userProfile.image_url.lastIndexOf('.'))
                                                 : undefined }/>
                                            <div style={{margin: '15px'}}>Quantity: {item.quantity}</div>

                                            {(userProfile && userProfile.frame === item._id) ?
                                                <div style={{color: 'green'}}>Currently equipped</div>
                                            :
                                                <ButtonLarge 
                                                    onClick={()=>{handleEquipFrame(item._id)}}
                                                    type={selectedFrameId === item._id ? 'success' : 'primary'}
                                                >
                                                    {selectedFrameId === item._id ? 'Confirm' : 'Equip frame'}                    
                                                </ButtonLarge>
                                            }
                                        </Panel>
                                    </Col>
                                )
                            })
                        :
                            <Col xs={12} sm={12} md={6} xl={4} xxl={4} key={Math.random()} style={{marginBottom: '24px'}}>
                                <Panel style={{height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    You have no item
                                </Panel>
                            </Col>
                         }
                    </Row>

                    <Panel style={{
                        background: 'linear-gradient(180deg, #ffeb3b 0%, #fdd835 100%)',
                        padding: '12px',
                        marginBottom: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{color: '#333'}}>All frames will be mintable on Polygon soon.</div>
                    </Panel>

                    <Panel style={{
                        marginBottom: '24px',
                        height: '400px', 
                        background: `url(${background})`, 
                        backgroundSize: 'cover', 
                        backgroundRepeat: 'no-repeat', 
                        backgroundPosition: 'bottom left',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '150px'
                    }}>
                        <Link to='/shop'>
                            <ButtonLarge type='primary' style={{width: '250px'}}>
                                Get more items &rarr;
                            </ButtonLarge>
                        </Link>
                    </Panel>
                </Container>
            : // else
                <Navigate to="/" />
            }
        </>
    )
}
