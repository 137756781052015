import React from 'react'

import questService from '../service/QuestService';
import authService from '../service/AuthService';
import ApiService from '../service/ApiService';

import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { Panel } from '../component/sharedComponents/sharedComponents';

import '../css/earn.css';
import FollowTypePanel from '../component/rewardPanels/FollowTypePanel';
import LoginTypePanel from '../component/rewardPanels/LoginTypePanel';
import CheckoutTypePanel from '../component/rewardPanels/CheckoutTypePanel';
import LikeTypePanel from '../component/rewardPanels/LikeTypePanel';


export default function RewardsPage ({ isLogged, quests, setQuests, refresh, doRewardAlert, shopData, updateScore, userProfile, doWarnigAlert }) {

    const navigate = useNavigate();
    
    async function handleClaimReward(quest) {
        if(quest.isCompleted) {
            let result;
            try {
                result = await questService.claimReward(quest.id);
            } catch (error) { 
                doWarnigAlert("Error: Reward was already claimed.");
            }

            if(result) {
                doRewardAlert(result.data.score_reward, result.data.credits_reward);
                updateScore(result.data.score_reward, result.data.credits_reward);
            }

            if(quest.type !== 'Login' && quest.targetTweet.twitterId !== userProfile.twitterId) {
                const questById = quests.reduce((obj, q) => ({...obj, [q.id]: q}), {});
                delete questById[quest.id];
                setQuests(Object.values(questById));
            } else {
                refresh();
            }
        }
    }

    async function handleClaimCheckout(checkout) {
        if(checkout.status === 'completed') {
            const response = await ApiService.get('/shop/checkout/claim', {checkoutId: checkout._id});
            if(response.data) {
                doRewardAlert(100*checkout.packs.length, checkout.totalCredit);
                // refresh is allowed since it should not happen to often
                refresh(); 
            }
        }
    }

    function setClassName(quest) {
        switch (quest.title) {
            case 'Daily login reward':
                return 'dailyQuest';
            case 'Follow founder':
                return 'followFounder';
            case 'Follow project':
                return 'followFlock';
            default:
                return '';
        }
    }

    async function handleExtraPanel() {
        if(isLogged) {
            navigate('/earn/create');
        } else {
            authService.auth();
        }
    }

    return (
        <Container>
            <div className='heroesLegend' style={{fontSize:'32px', textAlign: "center", color: 'white'}}>Earn</div>
            <div style={{height: "25px"}}/>
            <Row>

                {isLogged && shopData && shopData.checkoutsData && shopData.checkoutsData.map(checkout => {
                    if(checkout.status === 'completed') {
                        return (
                            <Col xs={12} sm={12} md={6} xl={4} xxl={4} key={Math.random()} style={{marginBottom: '24px'}}>
                                <CheckoutTypePanel checkout={checkout} classOption='dailyQuest' handleClaimCheckout={handleClaimCheckout}/>
                            </Col>
                        )
                    }
                })}

                {quests.map(quest => {
                    return (
                        <Col xs={12} sm={12} md={6} xl={4} xxl={4} key={Math.random()} style={{marginBottom: '24px'}}>
                            
                            {quest.type === 'Follow' && <FollowTypePanel isLogged={isLogged} quest={quest} classOption={setClassName(quest)} handleClaimReward={handleClaimReward}/>}
                            {quest.type === 'Login' && <LoginTypePanel isLogged={isLogged} quest={quest} classOption={setClassName(quest)} handleClaimReward={handleClaimReward}/>}
                            {quest.type === 'Like' && <LikeTypePanel isLogged={isLogged} quest={quest} classOption={setClassName(quest)} handleClaimReward={handleClaimReward}/>}

                        </Col>
                    )
                })}

                <Col xs={12} sm={12} md={6} xl={4} xxl={4} style={{marginBottom: '24px'}}>
                    <Panel className='rewardPanel extraPanel' onClick={handleExtraPanel}>
                        { isLogged ? 
                            <>
                                <div>
                                    Promote tweet<br />
                                    <div style={{fontSize: '250%'}}>+</div>
                                </div>
                            </>
                        : 
                            <div>
                                Login to earn rewards and promote tweets.
                            </div>
                        }
                    </Panel>
                </Col>

            </Row>
            <div style={{height: "25px"}}/>

        </Container>
    )
}
