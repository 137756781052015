import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize'

import { Container } from 'react-bootstrap';
import '../css/leaderboard.css';

import { Panel } from '../component/sharedComponents/sharedComponents';
import FrameDisplay from '../component/FrameDisplay';

export default function LeaderBoardPage ({ isLogged, leaderboardData, playerCount, shopData }) {

    const windowSize = useWindowSize();
    const navigate = useNavigate();

    const [framesById, setFramesById] = useState();

    useEffect(() => {
        if(shopData) {
            const result = shopData.frames.reduce((obj, frame) => ({...obj, [frame._id]: frame}), {});
            setFramesById(result);
        }
    }, [shopData])
    

    return (
        <Container>
            <Panel>
                <div className='heroesLegend pageTitle' style={{textAlign: "center", color: '#303030'}}>Leaderboard</div>
                <div style={{height: "25px"}}/>
                
                <div className="subtle" style={{position: 'absolute'}}>{`Total players: ${playerCount}`}</div>

                <table style={{width: '100%', tableLayout: 'fixed'}}>
                    <tbody>
                        <tr>
                            <th style={{width: '50px'}} />
                            <th style={{width: '50px'}}/>
                            <th style={{width: '100%'}}/>
                            {windowSize.width > 780 && <th style={{width: '100%'}}/> }
                            {windowSize.width > 320 && <th style={{width: '25%', textAlign: 'center'}} className='subtle'>
                                {windowSize.width > 992 && <>score&nbsp;</>}
                                <img src={require('../img/star.png')} alt='points' style={{ height: '35px', width: '35px', transform: 'translateY(-10px)'}} />
                            </th> }
                        </tr>
                        {framesById && leaderboardData.map(profile => {
                            return (
                                <tr className='leaderboard-row' style={{height: "80px", cursor: 'pointer'}} key={Math.random()} onClick={()=>{navigate(`/profile/${profile.username}`)}}>
                                    <td style={{width: '50px', textAlign: 'left', fontSize: "150%"}}>{!profile.rank ? "---" : profile.rank}</td>
                                    <td style={{width: '50px'}}>
                                        {profile.frame ? 
                                            <FrameDisplay size={75} frame={framesById[profile.frame].imageUrl} profile={profile.image_url}/>
                                        :
                                            <img src={profile.image_url} style={{borderRadius: '50%', marginRight: "5px"}} alt="profile"/>
                                        }
                                    </td>
                                    <td className='profile-name'>{profile.name}<br />                                
                                        <a 
                                            href={`https://twitter.com/${profile.username}`} target="_blank" rel="noreferrer"
                                            className='profileLink'
                                            style={{fontSize: "70%", color: 'lightblue'}}
                                        >
                                            {`@${profile.username}`}
                                        </a>    
                                    </td>
                                    {windowSize.width > 780 && 
                                        <td style={{width: '100%', fontSize: "70%"}}>
                                            <span className='subtle'>Followers:</span>{` ${profile.followers_count}`}<br />
                                            <span className='subtle'>Following:</span>{` ${profile.following_count}`}
                                        </td>
                                    }
                                    {windowSize.width > 320 && 
                                        <td className='profile-score'>{profile.score}</td>
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Panel>
            <div style={{height: "250px"}}/>
        </Container>
    )
}
