import axios from 'axios';
import Cookies from "universal-cookie";

import CONFIG from '../config.js';

const X_WWW_FORM_HEADER = { headers: { 'Content-Type': 'application/x-www-form-urlencoded'} }

class ApiService {

    constructor() {
        this.cookies = new Cookies();

        axios.defaults.baseURL = CONFIG.BACKEND_URL;
        axios.defaults.headers.common["authorization"] = `Bearer ${this.cookies.get("TOKEN")}`;
        axios.defaults.headers.common["accept"] = "application/json";
        axios.defaults.headers.common["content-Type"] = "application/json;charset=utf-8";
    }

    tempSetBearerToken(token) {
        axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
    }

    async get(resource, params) {
        return await axios.get(resource, !params ? undefined : {params: params});
    }

    async post(resource, data) {
        axios.defaults.headers.common["content-Type"] = undefined;
        const result = await axios.post(resource, data, X_WWW_FORM_HEADER);
        axios.defaults.headers.common["content-Type"] = "application/json;charset=utf-8";
        return result
    }

    async put(resource, data) {
        axios.defaults.headers.common["content-Type"] = undefined;
        const result = await axios.put(resource, data, X_WWW_FORM_HEADER);
        axios.defaults.headers.common["content-Type"] = "application/json;charset=utf-8";
        return result
    }

    async delete(resource) {
        return await axios.delete(resource);
    }
}

export default new ApiService();