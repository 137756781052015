import React from 'react'

import { Panel } from '../sharedComponents/sharedComponents';
import Countdown from '../CountDown';

import { FiLogIn } from 'react-icons/fi'

import authService from '../../service/AuthService';
import RewardsIcons from '../RewardsIcons';

export default function LoginTypePanel ({ isLogged, quest, classOption, handleClaimReward }) {
    
    const now = new Date();
    const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime();
    
    return (
        <Panel background={true} className={`rewardPanel ${classOption}`}>

            <RewardsIcons scoreAmount={quest.score_reward} creditAmount={quest.credits_reward} />
     
            <hr style={{marginBottom: '5px'}}/>

            <div style={{textAlign: 'center', fontSize: '70%', marginBottom: '5px'}}>{quest.title}</div>

            <div style={{display: 'grid', gridTemplateColumns: '50% 50%', height: '180px'}}>
                <div>&nbsp;</div>
                {(quest.isCompleted && !quest.isRewardGiven) ?
                    
                    <div style={{display:'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between'}}>
                        <div>&nbsp;</div>
                        <div className='claimButton' onClick={()=>{handleClaimReward(quest)}}>
                            Claim&nbsp;&nbsp;
                            <img src={require('../../img/gift.png')} alt='chest' style={{height: '30px'}} />
                            <div className='notification' style={{position: 'absolute', top: '-5px', right: '-5px', background: 'red', height: '20px', width: '20px', borderRadius: '50%'}} />
                        </div>
                    </div>
                    
                : // else
            
                    <div style={{height: '100%'}}>
                        
                        <div style={{display:'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between'}}>
                            <div>{quest.description}</div>
                            {!isLogged ? 
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <div className='followButton' onClick={()=>{authService.auth()}}>
                                        <FiLogIn style={{transform: 'translateY(-2px)'}}/>
                                        &nbsp;Login
                                    </div>
                                </div>
                            :
                                <div style={{fontSize: '70%'}}>
                                    <Countdown endTime={endTime} />
                                    Resets at midnight
                                </div>
                            }
                        </div>

                    </div> 
                }
            </div>
        </Panel>
    )
}
