import { SiEthereum } from 'react-icons/si';

import { ButtonLarge } from './sharedComponents/sharedComponents';

export default function CartSummaryTable({ cart, setCart }) {
    
    function handleRemove(pack) {
        const newList = [];
        let hasRemoved = false;
        cart.forEach(item => {
            if(!hasRemoved && item.id === pack.id) {
                hasRemoved = true;
            } else {
                newList.push(item)
            }
        })
        setCart(newList);
    }
    
    return (
        <>
            {cart && 
                <table style={{width: '100%', tableLayout: 'fixed'}}>
                    <tbody>
                        <tr>
                            <th>Pack Id</th>
                            <th>Credit Amount</th>
                            <th>Price <SiEthereum /></th>
                            {setCart && <th style={{width: '24px'}}>&nbsp;</th>}
                        </tr>
                        {cart.map(pack => {
                            return (
                                <tr key={Math.random()}>
                                    <td>{pack.id.substring(20,24)}</td>
                                    <td>{pack.creditAmount}</td>
                                    <td>{pack.packPrice}</td>
                                    {setCart && 
                                    <td>
                                        <ButtonLarge type='danger' style={{padding: '0px 0px', minWidth: '24px', width: '24px'}} onClick={()=>{handleRemove(pack)}}>
                                            x
                                        </ButtonLarge>
                                    </td>
                                    }
                                </tr>
                            )
                        })}
                        <tr style={{borderTop: '1px solid rgba(0,0,0,0.25)'}}>
                            <td><b>Total:</b></td>
                            <td>{cart.reduce((sum, elem) => { return sum + elem.creditAmount }, 0)}</td>
                            <td>{cart.reduce((sum, elem) => { return sum + (elem.packPrice * 1000000000) }, 0) / 1000000000} <SiEthereum /></td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}