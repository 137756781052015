import apiService from "./ApiService";

class QuestService {

    async get() {
        return await apiService.get('/quest');
    }

    async post(quest) {
        return await apiService.post('/quest', quest)
    }

    async put(quest) {
        return await apiService.put('/quest', quest)
    }

    async verifyCompletion(questId) {
        return await apiService.get(`/quest/verifyCompletion`, {questId: questId});
    }

    async claimReward(questId) {
        return await apiService.get('/quest/claimReward', {questId: questId})
    }
}

export default new QuestService();