import React, { useState } from 'react'

import twitterService from '../../service/TwitterService';
import ApiService from '../../service/ApiService';
import authService from '../../service/AuthService';

import { FiHeart } from 'react-icons/fi'

import { Panel } from '../sharedComponents/sharedComponents';
import RewardsIcons from '../RewardsIcons';
import Countdown from '../CountDown';

export default function LikeTypePanel ({ isLogged, quest, classOption, handleClaimReward, disableButtons }) {

    const [isExpired, setIsExpired] = useState(false);

    async function handleLikeTweet() {
        if(isLogged) {
            // const like = await twitterService.like(quest.targetTweet.tweetId);
            const response = await ApiService.post('/twitter/like', {tweetId: quest.targetTweet.tweetId});
            if(response.data) { 
                const completedQuest = Object.assign({}, quest);
                completedQuest.isCompleted = true;
                handleClaimReward(completedQuest)
            }
        } else {
            authService.auth();
        }

    }

    function parseDate(IsoDate) {
        const createdAt = new Date(IsoDate);
        return createdAt.toDateString();
    }

    return (
        <Panel background={false} className={`rewardPanel ${classOption}`}>

            <RewardsIcons scoreAmount={quest.score_reward} creditAmount={quest.credits_reward} />
            
            <hr style={{marginBottom: '5px'}}/>

            <div style={{textAlign: 'center', fontSize: '70%'}}>{quest.title}</div>
            
            <div style={{height: '100%'}}>
                
                <div style={{display: 'block'}}>
                    <div style={{fontFamily: 'Roboto', fontSize: '70%'}}>
                        {quest.targetProfile.name}
                    </div>
                    <div style={{fontSize: '70%', color: 'lightblue', marginBottom: '5px'}}>{`@${quest.targetProfile.username}`}</div>
                    <div style={{textAlign: 'justify', marginBottom: '10px'}}>
                        {quest.targetTweet.text}
                    </div> 
                    <div style={{fontSize: '50%'}}>{parseDate(quest.targetTweet.created_at)}</div>
                </div>
            </div> 
            
            <hr style={{marginBottom: '15px'}}/>
            
            <div style={{display: 'grid', gridTemplateColumns: '50% 50%', marginBottom: '15px'}}>
                
                {(!disableButtons && quest.dateEnd) ? <Countdown endTime={Date.parse(quest.dateEnd)} setIsExpired={setIsExpired} /> : <div>&nbsp;</div>}
                
                {(!quest.isRewardGiven && !disableButtons && !isExpired) &&
                    <>
                        {quest.isCompleted ?
                            <div style={{display:'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between'}}>
                                <div>&nbsp;</div>
                                <div className='claimButton'
                                    onClick={()=>{handleClaimReward(quest)}}
                                >
                                    Claim&nbsp;&nbsp;
                                    <img src={require('../../img/gift.png')} alt='chest' style={{height: '30px'}} />
                                    <div className='notification' style={{position: 'absolute', top: '-5px', right: '-5px', background: 'red', height: '20px', width: '20px', borderRadius: '50%'}} />
                                </div>
                            </div>
                        :
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                                <div className='likeButton' onClick={handleLikeTweet}>
                                    <FiHeart style={{transform: 'translateY(-1px)'}}/>&nbsp;Like
                                </div>
                            </div>
                        }
                    </>
                }
            </div> 

            <div style={{textAlign:'center'}}>
                <a 
                    href={`https://twitter.com/${quest.targetProfile.twitterId}/status/${quest.targetTweet.tweetId}`} 
                    target='_blank' 
                    style={{fontSize: '70%', textDecoration: 'underline', color: 'lightblue', textAlign: 'center'}}
                >{`View on twitter ->`}</a>
            </div>   
        </Panel>
    )
}
