import React from 'react';

export default function ProfileBox({ profile }) {
   return (
    <div id='profileBox' style={{display: 'block', background: '#fff', boxShadow: '0px 0px 5px rgba(0,0,0,0.25)', borderRadius: '10px', padding: '10px'}}>
      <div className='targetProfile' style={{fontSize: '70%'}}>{profile.name}</div>
      <div style={{fontSize: '70%'}}>{`@${profile.username}`}</div>
      <div style={{fontSize: '70%'}}>
        <span style={{color: 'lightblue'}}>Followers:&nbsp;</span>{profile.followers_count}<br />
        <span style={{color: 'lightblue'}}>Following:&nbsp;</span>{profile.following_count}<br />
        <span style={{color: 'lightblue'}}>Tweets:&nbsp;</span>{profile.tweet_count}
      </div>
    </div>
  );
}

