import React, { useState } from 'react';

import CONFIG from '../config';

import apiService from '../service/ApiService';

import { Container } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents'

export default function AdminPage({ isLogged, userProfile }) {

    const [amountInput, setAmountInput] = useState();
    const [priceInput, setPriceInput] = useState();

    const [startOfDayLog, setStartOfDayLog] = useState('');
    function adminLog(text) {
        setStartOfDayLog(startOfDayLog + `\n${text}`)
    }

    async function handleStartOfDay() {
        const response = await apiService.get(`${CONFIG.BACKEND_URL}/sod/start`);
        adminLog(response.data);
    }

    async function testQuery() {
        const response = await apiService.get(`${CONFIG.BACKEND_URL}/test`);
        console.log(response);
    }

    async function handleCreatePack() {
        if(amountInput && priceInput) {
            const response = await apiService.post(`${CONFIG.BACKEND_URL}/shop/createPack`, {price: priceInput, creditAmount: amountInput});
            adminLog(response.data)
        }
    }

    return (
        <>
            {/* {isLogged && userProfile.accessLevel === 'admin' ?  */}
            {true ? 
                <Container>
                    <Panel>
                        <div className='heroesLegend pageTitle' style={{textAlign: "center", color: '#303030'}}>Admin</div>
                        <div style={{height: "25px"}}/>
                        <hr />
                        <div>Start of day</div>
                        <div style={{height: "15px"}}/>
                        <ButtonLarge type={'primary'} onClick={handleStartOfDay}>Start</ButtonLarge>
                        <hr />
                        <ButtonLarge type={'primary'} onClick={testQuery}>test</ButtonLarge>
                        <hr />
                        <div>Credit amount input</div>
                        <input type='number' id='amountInput' onChange={(event)=>{setAmountInput(event.target.value)}}/>
                        <div>Price input</div>
                        <input type='number' id='costInput' onChange={(event)=>{setPriceInput(event.target.value)}}/>
                        <div style={{height: "15px"}}/>
                        <ButtonLarge type={'primary'} onClick={handleCreatePack}>Create</ButtonLarge>
                    </Panel>
                    <div style={{height: "25px"}}/>
                    <Panel>
                        <div id='log' style={{whiteSpace: 'pre-wrap'}}>{startOfDayLog}</div>
                    </Panel>
                </Container>
                
            : // else
                <Navigate to="/" />
            }
        </>
    )
}