import styled from 'styled-components'

const ButtonLarge = styled.div`
    background: ${props => {
        switch (props.type) {
            case 'danger':
                return '#ff6b6b';
            case 'success':
                return '#92CC41';
            case 'inactive':
                return '#dedede';
            
            default: // aka primary
                return '#5CD1F0';
        }
    }};
    color: ${props => props.type === 'inactive' ? '#ccc' : 'black' };
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 15px 35px;
    min-width: 150px;
    cursor: ${props => props.type === 'inactive' ? 'not-allowed' : 'pointer' };
    transition: all 0.2s ease-in-out;

    &:hover {
        ${props => props.type === 'inactive' ? '' : 'box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35)'};
    }
`

const Button = styled.div`
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    }
`

const Panel = styled.div`
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.24);
    border-radius: 15px;
    width: 100%;
    padding: 15px;
    
    ${props => {
        if(!props.background) {
            return 'background: rgba(253, 253, 253, 1);'
        }
    }}
`

export { Button, ButtonLarge, Panel }