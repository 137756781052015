import React, { useState, useEffect } from 'react'
import { Panel, ButtonLarge } from '../component/sharedComponents/sharedComponents';
import CartSummaryTable from '../component/CartSummaryTable';
import { Navigate } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { SiEthereum } from 'react-icons/si'

import background from '../img/chest_M.png';
import RewardsIcons from '../component/RewardsIcons';

export default function BuyCreditsPage ({ isLogged, shopData, cart, setCart, hasPendingCheckout, pendingCheckoutId }) {
    
    function handleSelect(pack) {
        setCart(cart => [...cart, pack]);
    }
    
    return (
        <>
            {!hasPendingCheckout ?
                <Container>
                    <div className='heroesLegend' style={{fontSize:'32px', textAlign: "center", color: "white"}}>Buy Credits</div>
                    <div style={{height:"25px"}} />

                    <Row>
                        {shopData.packs && shopData.packs.map(pack => {
                            return (
                                <Col xs={12} sm={12} md={6} xl={4} xxl={4} key={Math.random()} style={{marginBottom: '24px'}}>
                                    <Panel style={{background: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom left'}}>
                                        <RewardsIcons creditAmount={pack.creditAmount} scoreAmount={100} />
                                        <hr style={{marginBottom: '0px'}}/>
                                        <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
                                            <>&nbsp;</>
                                            <div>
                                                <div style={{fontSize: '60%', marginBottom: '20px'}}>Pack of {pack.creditAmount} credits</div>
                                                <div style={{fontSize: '100%', marginBottom: '10px', textAlign: 'center'}}>{pack.price} <SiEthereum /></div>
                                                <ButtonLarge 
                                                    type='primary' 
                                                    onClick={()=>{handleSelect({ creditAmount: pack.creditAmount, id: pack._id, packPrice: pack.price })}}
                                                >
                                                    Select
                                                </ButtonLarge>
                                            </div>
                                        </div>
                                    </Panel>
                                </Col>
                            )
                        })}
                    </Row>
                    <Panel style={{marginBottom: '24px'}}>
                        <div style={{marginBottom: '15px'}}>Details</div>
                        <CartSummaryTable cart={cart} setCart={setCart} />
                    </Panel>
                    <div>
                        <Link to='/checkout'>
                            <ButtonLarge type={cart.length > 0 ? 'success' : 'inactive' }>
                                Checkout &rarr;
                            </ButtonLarge>
                        </Link>
                    </div>
                </Container>
            :
                <Navigate to={`/checkout/${pendingCheckoutId}`} />
            }
        </>
    )
}
