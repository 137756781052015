import React from 'react'

import { Panel } from '../sharedComponents/sharedComponents';
import RewardsIcons from '../RewardsIcons';

export default function CheckoutTypePanel ({ checkout, classOption, handleClaimCheckout }) {
    return (
        <Panel background={true} className={`rewardPanel ${classOption}`}>

            <RewardsIcons scoreAmount={100 * checkout.packs.length} creditAmount={checkout.totalCredit} />
            
            <hr style={{marginBottom: '5px'}}/>

            <div style={{textAlign: 'center', fontSize: '70%', marginBottom: '5px'}}>Bought pack</div>

            <div style={{display: 'grid', gridTemplateColumns: '50% 50%', height: '180px'}}>
                <div>&nbsp;</div>
                    
                <div style={{display:'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between'}}>
                    <div>&nbsp;</div>
                    <div className='claimButton' onClick={()=>{handleClaimCheckout(checkout)}}>
                        Claim&nbsp;&nbsp;
                        <img src={require('../../img/gift.png')} alt='chest' style={{height: '30px'}} />
                        <div className='notification' style={{position: 'absolute', top: '-5px', right: '-5px', background: 'red', height: '20px', width: '20px', borderRadius: '50%'}} />
                    </div>
                </div>
            </div>
        </Panel>
    )
}
