import React from 'react'

import twitterService from '../../service/TwitterService';
import authService from '../../service/AuthService';

import { SlUserFollow } from 'react-icons/sl'
import { Panel } from '../sharedComponents/sharedComponents';
import ProfileBox from '../ProfileBox';
import RewardsIcons from '../RewardsIcons';

export default function FollowTypePanel ({ isLogged, quest, classOption, handleClaimReward }) {
    
    async function handleFollow() {
        if(isLogged) {
            const follow = await twitterService.follow(quest.targetProfile.twitterId);
            if(follow) {
                handleClaimReward(quest);
            }
        } else {
            authService.auth();
        }
    }
    
    return (
        <Panel background={true} className={`rewardPanel ${classOption}`}>

            <RewardsIcons scoreAmount={quest.score_reward} creditAmount={quest.credits_reward} />
            
            <hr style={{marginBottom: '5px'}}/>

            <div style={{textAlign: 'center', fontSize: '70%', marginBottom: '5px'}}>{quest.title}</div>

            <div style={{display: 'grid', gridTemplateColumns: '50% 50%', height: '180px'}}>
                <div>&nbsp;</div>
                {quest.isCompleted ?
                    
                    <div style={{display:'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between'}}>
                        <div>&nbsp;</div>
                        <div className='claimButton'
                            onClick={()=>{handleClaimReward(quest);}}
                        >
                            Claim&nbsp;&nbsp;
                            <img src={require('../../img/gift.png')} alt='chest' style={{height: '30px'}} />
                            <div className='notification' style={{position: 'absolute', top: '-5px', right: '-5px', background: 'red', height: '20px', width: '20px', borderRadius: '50%'}} />
                        </div>
                    </div>
                    
                : // else
            
                    <div style={{height: '100%'}}>
                        
                        <div style={{display:'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', paddingTop: '5px'}}>
                            <ProfileBox profile={quest.targetProfile} />
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div className='followButton' onClick={handleFollow}>
                                    <SlUserFollow style={{transform: 'translateY(-2px)'}}/>
                                    &nbsp;Follow
                                </div>
                            </div>
                        </div>

                    </div> 

                }
            </div>
        </Panel>
    )
}
