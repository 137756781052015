import apiService from "./ApiService";

class TwitterService {

    async getCurrentUserProfile() {
        const response = await apiService.get('/twitter/currentUser');
        return response.data;
    }

    async follow(twitterId) {
        const response = await apiService.put('/twitter/follow', {twitterId: twitterId})
        return response.data;
    }

    async like(tweetId) {
        const response = await apiService.post('/twitter/like', {tweetId: tweetId});
        return response.data;
    }

    async getProfileByUsername(username) {
        const response = await apiService.get(`/twitter/profile?username=${username}`);
        return response.data;
    }

}

export default new TwitterService();