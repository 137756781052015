import React from 'react'
import { ButtonLarge } from '../component/sharedComponents/sharedComponents';
import RewardsIcons from './RewardsIcons';
import '../css/createRewards.css'
export default function RewardBox({ template, selectedReward, handleRewardBoxSelected, handleNextStepButton }) {
    return (
        <div className='rewardBoxes'>
            <div className='rewardTitle'>{template.title}</div>
            <RewardsIcons scoreAmount={template.score_reward} />
            <div style={{textAlign: 'center',marginTop: '15px'}}>For every like</div>
            <div style={{textAlign: 'center', marginBottom: '15px'}}>For 2h</div>
            {(selectedReward && selectedReward._id === template._id) ? 
                <ButtonLarge type='success' onClick={()=>{handleNextStepButton()}}>
                    Confirm &rarr;
                </ButtonLarge>
            :
                <ButtonLarge type='primary' onClick={()=>{handleRewardBoxSelected(template)}}>
                    Select
                </ButtonLarge>
            }
            <div style={{textAlign: 'center',marginTop: '15px'}}>
                Cost: <b>{template.cost}</b>
                <img src={require('../img/coin.png')} alt='points' style={{ height: '24px', width: '24px', transform: 'translateY(-3px)'}} />
            </div>
        </div>
    );
}